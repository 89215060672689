const state = () => ({ viewingRequests: [], assignings: [], listing: null });

const getters = {
    getViewingRequests(state) {
        let viewing_requests = [];

        for (let vr of state.viewingRequests) {
            let sanction = "green";
            let flags = [];

            for (let t of vr.user.tenants) {
                let results = t.wwft_data?.results ?? [];
                results = results.filter((x) => x.score == 100);
                for (let wwft of results) {
                    flags = [...flags, ...wwft.flags];
                }
            }

            if (flags.length) {
                sanction = "yellow";
            }

            if (flags.includes("Current Sanctions")) {
                sanction = "red";
            }

            let profesional_less_than_2 = vr.user.tenants.find(
                (x) => x.professional_years == "less_than_2"
            );

            let merged = {
                ...vr,
                ...{ sanction: sanction, flags: flags },
                ...{ profesional_less_than_2: profesional_less_than_2 },
                ...{
                    rent_period: vr.user.rent_period,
                    rent_months: vr.user.rent_months,
                },
            };

            console.log(
                "profesional_less_than_2",
                merged.profesional_less_than_2
            );
            viewing_requests.push(merged);
        }

        return viewing_requests;
    },
    listing(state) {
        return state.listing;
    },
    user(state) {
        return state.listing?.assigned?.user;
    },
    assignings(state) {
        return state.assignings.map((assigning) => {
            if (!assigning.user) return assigning;

            let sanction = "green";
            let flags = [];

            let riskcode = 1;

            let score = 0;
            for (let t of assigning.user.tenants) {
                let results = t.wwft_data?.results ?? [];
                results = results.filter((x) => x.score == 100);
                for (let wwft of results) {
                    flags = [...flags, ...wwft.flags];
                }

                if (t.edr_riskcode == "error") {
                    riskcode = 2;
                }

                if (t.edr_riskcode == "red") {
                    riskcode = 3;
                }
            }

            if (flags.length) {
                sanction = "yellow";
            }

            if (flags.includes("Current Sanctions")) {
                sanction = "red";
            }

            if (riskcode == 1) {
                score += 2;
                console.log(assigning.id, "riskcode score");
            } else if (riskcode == 2) {
                score += 1.33;
            }

            if (assigning?.appointment?.sufficientIncome) {
                score += 2;
                console.log(assigning.id, "sufficientIncome score");
            }

            if (sanction == "green") {
                score += 2;
                console.log(assigning.id, "sanction score");
            } else if (sanction == "yellow") {
                score += 1.33;
            }

            if (
                state.listing.preferredHousehold
                    .toLowerCase()
                    .indexOf(
                        assigning.user.householdComposition.toLowerCase()
                    ) > -1
            ) {
                console.log(assigning.id, "preferredHousehold sanction");
                score += 2;
            }

            console.log(
                assigning.id,
                assigning.user.pets,
                state.listing.preferredAnimals,
                "preferredAnimals sanction"
            );
            if (state.listing.preferredAnimals == assigning.user.pets) {
                score += 2;
            }

            let merged = {
                ...assigning.user,
                ...assigning,
                ...{
                    sanction: sanction,
                    flags: flags,
                    riskcode: riskcode,
                    score: score,
                },
            };

            let profesional_less_than_2 = assigning.user.tenants.find(
                (x) => x.professional_years == "less_than_2"
            );
            merged.profesional_less_than_2 = profesional_less_than_2;

            merged.sufficientIncome = assigning?.appointment?.sufficientIncome;

            return merged;
        });
    },
};
const mutations = {
    setViewingRequests(state, payload) {
        state.viewingRequests = payload;
    },
    setListing(state, payload) {
        state.listing = payload;
    },
    setAssignings(state, payload) {
        console.log("setAssignings", state, payload);
        state.assignings = payload;
    },
    updateViewingRequests(state, payload) {
        const viewingReqIdx = state.viewingRequests.findIndex(
            (req) => req.id === payload.reqId
        );
        if (viewingReqIdx > -1) {
            state.viewingRequests.splice(viewingReqIdx, 1, {
                ...state.viewingRequests[viewingReqIdx],
                ...payload.updatedReq,
            });
        }
    },
    deleteViewingRequest(state, payload) {
        const viewingReqIdx = state.viewingRequests.findIndex(
            (req) => req.id === payload.id
        );
        if (viewingReqIdx > -1) {
            state.viewingRequests.splice(viewingReqIdx, 1);
        }
    },
    updateAssigning(state, payload) {
        const assigningidx = state.assignings.findIndex(
            (assigning) => assigning.id === payload.id
        );
        if (assigningidx > -1) {
            const assigning = {
                ...state.assignings[assigningidx],
                ...payload.updates,
            };
            state.assignings.splice(assigningidx, 1, assigning);
        }
    },
};

const actions = {
    async setViewingRequests({ dispatch, commit }, payload = {}) {
        let { listingId } = payload;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "viewingRequests", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "viewingRequests", state: "" },
                { root: true }
            );
            const { data } = await window.axios.post(`/broker/appointments`, {
                listing_id: listingId,
            });
            commit("setViewingRequests", data.appointments);
            commit("setListing", data.listing);
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "viewingRequests", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "viewingRequests", state: false },
                { root: true }
            );
        }
    },
    async appointmentCancel({ dispatch }, payload = {}) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "invitation_cancel", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "invitation_cancel", state: "" },
                { root: true }
            );

            await window.axios.post("/broker/appointment/cancel", payload);
            // return commit("deleteViewingRequest", {
            //     id: payload.appointment_id,
            // });
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "invitation_cancel", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "invitation_cancel", state: false },
                { root: true }
            );
        }
    },

    async appointmentInvite({ commit, dispatch }, payload) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "invitation", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "invitation", state: "" },
                { root: true }
            );

            const updatedAppointment = await window.axios.post(
                "/broker/appointment/invite",
                payload
            );
            commit("updateViewingRequests", {
                reqId: payload.appointment_id,
                updatedReq: updatedAppointment,
            });
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "invitation", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "invitation", state: false },
                { root: true }
            );
        }
    },
    async getAssignings({ dispatch, commit }, payload = {}) {
        let { listingId } = payload;
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "assigning", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "assigning", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(`/broker/assignings`, {
                params: {
                    listing_id: listingId,
                },
            });
            if (data.listing) {
                commit("setAssignings", data.assignings);
                commit("setListing", data.listing);
            }
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "assigning", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "assigning", state: false },
                { root: true }
            );
        }
    },
    async getRentLong({ dispatch, commit }, listing_id) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "rent_log", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "rent_log", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/user/listings/${listing_id}/rent-log`
            );
            if (data.listing) {
                commit("setAssignings", data.assignings);
                commit("setListing", data.listing);
            }
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "rent_log", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "rent_log", state: false },
                { root: true }
            );
        }
    },
    async startAssigning({ dispatch }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "assigning", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "assigning", state: "" },
                { root: true }
            );

            let data = await dispatch("updateStatus", "Assign a candidate");
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "assigning", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "assigning", state: false },
                { root: true }
            );
        }
    },
    async assignCandidate({ dispatch }, payload = {}) {
        let { assigningId } = payload;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "assigning", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "assigning", state: "" },
                { root: true }
            );
            const data = await window.axios.post("/broker/assign/list", {
                assigning_id: assigningId,
            });
            // commit("updateAssigning", {
            //     id: assigningId,
            //     updates: { assigned: body.assigned },
            // });
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "assigning", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "assigning", state: false },
                { root: true }
            );
        }
    },
    async cancelAssigning({ dispatch, state }, payload) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "cancelAssigning", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "cancelAssigning", state: "" },
                { root: true }
            );
            const { data } = await window.axios.post(`/broker/assign/cancel`, {
                listing_id: state.listing.id,
                ...payload,
            });
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "cancelAssigning", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "cancelAssigning", state: false },
                { root: true }
            );
        }
    },

    async updateStatus({ state }, status) {
        const { data } = await window.axios.post(
            `/broker/listings/${state.listing.id}/update-status`,
            { status: status }
        );
        return data;
    },
    async shareRentLog({ state, dispatch }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "share_rent_log", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "share_rent_log", state: "" },
                { root: true }
            );
            const { data } = await window.axios.post(
                `/broker/listings/${state.listing.id}/share-rent-log`
            );
            return data;
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            console.log(message);
            dispatch(
                "loadingError/setError",
                { type: "share_rent_log", state: message },
                { root: true }
            );
            throw new Error(message);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "share_rent_log", state: false },
                { root: true }
            );
        }
    },
    async getListingAgreementTemplate({ dispatch }, listing_id) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "agreement_template", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "agreement_template", state: "" },
                { root: true }
            );
            const { data } = await window.axios.get(
                `/broker/listings/${listing_id}/agreement-template`
            );
            return data;
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            console.log(message);
            dispatch(
                "loadingError/setError",
                { type: "agreement_template", state: message },
                { root: true }
            );
            throw new Error(message);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "agreement_template", state: false },
                { root: true }
            );
        }
    },
    async saveAgreementData({ dispatch }, { listing_id, payload }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "agreement_template", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "agreement_template", state: "" },
                { root: true }
            );
            const { data } = await window.axios.post(
                `/broker/listings/${listing_id}/agreement`,
                payload
            );
            return data;
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            console.log(message);
            dispatch(
                "loadingError/setError",
                { type: "agreement_template", state: message },
                { root: true }
            );
            throw new Error(message);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "agreement_template", state: false },
                { root: true }
            );
        }
    },

    async exportOverview({ dispatch, state }) {
        console.log("exportOverview");
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "appointment_export_overview", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "appointment_export_overview", state: "" },
                { root: true }
            );
            window
                .axios({
                    url: `/broker/listings/${state.listing.id}/appointment/export-overview`,
                    method: "GET",
                    responseType: "blob",
                })
                .then((response) => {
                    const href = window.URL.createObjectURL(response.data);

                    const anchorElement = document.createElement("a");

                    anchorElement.href = href;
                    anchorElement.download = `Overzicht - ${state.listing.title}.xlsx`;

                    document.body.appendChild(anchorElement);
                    anchorElement.click();

                    document.body.removeChild(anchorElement);
                    window.URL.revokeObjectURL(href);
                })
                .catch((error) => {
                    console.log("error: ", error);
                });
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            console.log(message);
            dispatch(
                "loadingError/setError",
                { type: "appointment_export_overview", state: message },
                { root: true }
            );
            throw new Error(message);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "appointment_export_overview", state: false },
                { root: true }
            );
        }
    },
};

export const requestsAndAppointmentModule = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
