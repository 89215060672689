import { render, staticRenderFns } from "./questionmark.vue?vue&type=template&id=627b3832&scoped=true&"
import script from "./questionmark.vue?vue&type=script&lang=js&"
export * from "./questionmark.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "627b3832",
  null
  
)

export default component.exports