const state = () => ({
    user: null,
    user_detail: null,
    token: null,
    broker_token: null,
    userType: null,
    otpSent: false,
    locale: "en",
});

const getters = {
    getAccessToken(state) {
        return state.token;
    },
    isAuth(state) {
        return state.token ? true : false;
    },
    user(state) {
        return state.user;
    },
    guarantor(state) {
        return state.user.guarantor;
    },
    entrepreneurs(state) {
        return state.user.tenants.filter((x) => x.profession == "entrepreneur");
    },
    notVerifiedEntrepreneurs(state) {
        return state.user.tenants.filter(
            (x) =>
                x.profession == "entrepreneur" && x.document_uploaded === false
        );
    },
    tenantsVerified(state) {
        let verified = false;

        let tenants = state?.user?.tenants || [];

        if (tenants.length != state?.user?.totalPersons) {
            return false;
        }

        if (state?.user?.tenants?.length) {
            verified = true;
            for (let tenant of tenants) {
                verified =
                    verified &&
                    tenant.identity_checked &&
                    tenant.income_checked &&
                    tenant.aml_checked;
            }
        }

        verified = verified && state?.user?.document_verified;
        verified =
            verified &&
            state?.user?.preferredHousingType != null &&
            state?.user?.preferredHousingType?.length != 0;

        return verified;
    },
    anyEntrepreneur(state) {
        for (let tenant of state.user.tenants) {
            if (tenant.profession == "entrepreneur") {
                return true;
            }
        }

        return false;
    },
    entrepreneurDocumentsVerified() {},
    user_detail(state) {
        return state.user_detail;
    },
    getUser() {
        let user = localStorage.getItem("user");
        user = JSON.parse(user);
        return user;
    },
    getUserType(state) {
        return state.userType;
    },
    userType(state) {
        return state.userType;
    },
    profileCompleted(state) {
        return state.user && state.user.first_name && state.user.last_name;
    },
    otpSent(state) {
        return state.otpSent;
    },
    locale(state) {
        return state.locale;
    },
};

const mutations = {
    setUserData(state, payload) {
        let user = payload;
        state.user = user;
        localStorage.setItem("user", JSON.stringify(user));
    },
    setToken(state, payload) {
        console.log("setToken", payload);
        state.token = payload;
        window.axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${payload}`;
        localStorage.setItem("accessToken", payload);
    },
    setUserType(state, payload) {
        console.log("setUserType", payload);
        state.userType = payload;
    },
    removeAuthData(state) {
        state.user = null;
        state.token = null;
        state.userType = null;
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
    },
    removeTennant(state, payload) {
        const tennantIdx = state.user.tannents.findIndex(
            (t) => t.id === payload
        );
        if (tennantIdx > -1) {
            state.user.tannents.splice(tennantIdx, 1);
        }
    },
    setUserDetail(state, user_detail) {
        state.user_detail = user_detail;
    },
    updateOTPSent(state, otpSent) {
        state.otpSent = otpSent;
    },
    setLocale(state, locale) {
        state.locale = locale;
    },
};

const actions = {
    switchLocale({ commit }, locale) {
        console.log("switching", locale);
        window.Weglot.switchTo(locale);
        commit("setLocale", locale);
    },
    async auth({ commit, dispatch }, payload) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "auth", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "auth", state: "" },
                { root: true }
            );
            const { body, url } = payload;

            const { data } = await window.axios.post(`${url}`, body);
            commit("setUserData", data.user);
            commit("setToken", data.accessToken);
            commit("setUserType", body.type);

            return data;
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            dispatch(
                "loadingError/setError",
                { type: "auth", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "auth", state: false },
                { root: true }
            );
        }
    },
    async verify({ dispatch, commit, state }, payload) {
        const { query } = payload;
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "verification", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "verification", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(`/email/verify`, {
                params: query,
            });
            commit("setUserData", { ...state.user, verified: true });
            return data;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "verification", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "verification", state: false },
                { root: true }
            );
        }
    },
    async sendOTP({ dispatch, commit, state }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "sending_otp", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "sending_otp", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/${state.userType}/send-otp`
            );
            commit("updateOTPSent", true);
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "sending_otp", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "sending_otp", state: false },
                { root: true }
            );
        }
    },
    async verifyOTP({ dispatch, commit, state }, { otp }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "verify_otp", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "verify_otp", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(
                `/${state.userType}/verify-otp`,
                {
                    otp,
                    broker_id: state.user.id,
                }
            );
            commit("setToken", data.access_token);

            return data;
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            dispatch(
                "loadingError/setError",
                { type: "verify_otp", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "verify_otp", state: false },
                { root: true }
            );
        }
    },
    async getUserDetail({ dispatch, rootState, commit }) {
        console.log("getUserDetail");
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "get_user", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "get_user", state: "" },
                { root: true }
            );
            let userType = rootState.auth.userType;

            const { data } = await window.axios.get(`/${userType}/detail`);
            commit("setUserDetail", data.user);
            commit("setUserData", data.user);
            return data.user;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "get_user", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "get_user", state: false },
                { root: true }
            );
        }
    },
    setUserData({ commit }, payload) {
        commit("setUserData", payload);
    },
    removeAuthData({ commit }) {
        commit("removeAuthData");
    },
    removeTennant({ commit }, payload) {
        commit("removeTennant", payload);
    },
    async logout({ commit, state, dispatch }) {
        const userType = state.userType === "broker" ? "broker" : "user";
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "logout", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "logout", state: "" },
                { root: true }
            );

            // await sendRequest({
            //   method: "GET",
            //   url,
            // });
            // await window.axios.post('/logout');
            commit("removeAuthData");
            window.location.href = `/${userType}/login`;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "logout", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "logout", state: false },
                { root: true }
            );
        }
    },
    async forgotPassword({ dispatch }, payload) {
        const { body } = payload;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "resetPass", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "resetPass", state: "" },
                { root: true }
            );

            const data = await window.axios.post("/password/forgot", body);
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "resetPass", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "resetPass", state: false },
                { root: true }
            );
        }
    },

    async resetPass({ dispatch }, payload) {
        const { body } = payload;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "resetPass", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "resetPass", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post("/password/reset", body);
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "resetPass", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "resetPass", state: false },
                { root: true }
            );
        }
    },
};

export const authModule = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
