const actions = {
    async createPayment({ dispatch }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "payment", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "payment", state: "" },
                { root: true }
            );
            const { data } = await window.axios.post("/user/payment/create");
            return data;
        } catch (error) {
            let message = error?.response?.data?.detail ?? error.message;
            dispatch(
                "loadingError/setError",
                { type: "payment", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "payment", state: false },
                { root: true }
            );
        }
    },
};

export const paymentModule = {
    actions,
    namespaced: true,
};
