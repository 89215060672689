var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-sidebar',{staticClass:"header__mobileMenu",attrs:{"id":"mobile-menu","bg-variant":"white","shadow":"","backdrop-variant":"dark","visible":_vm.showMenu,"backdrop":"","body-class":"header__mobileMenu__body"},on:{"hidden":function($event){return _vm.$emit('close', false)}}},[_c('div',{},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('ul',{staticClass:"header__mobileMenu__linksContainer p-0"},[_vm._l((_vm.links),function(link){return _c('li',{key:link.name,staticClass:"position-relative pt-3"},[(link.external)?_c('a',{staticClass:"header__mobileMenu__linksContainer__links__link",class:{
          broker: _vm.getUserType == 'broker',
          user: _vm.getUserType === 'user',
        },attrs:{"href":link.to,"target":"_blank"}},[_c('span',{staticClass:"header__linksContainer__links__link"},[_vm._v(_vm._s(link.name))])]):_c('router-link',{staticClass:"header__mobileMenu__linksContainer__links__link",class:{
          broker: _vm.getUserType == 'broker',
          user: _vm.getUserType === 'user',
        },attrs:{"to":link.to}},[_c('span',{staticClass:"header__linksContainer__links__link"},[_vm._v(_vm._s(link.name))])])],1)}),(_vm.username && _vm.getUserType === 'broker')?_c('li',{staticClass:"pt-3"},[_c('routerLink',{staticClass:"d-flex text-dark fw-bold",attrs:{"to":_vm.profileRoute}},[(_vm.avatar)?_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.avatar,"width":"24","height":"24"}}):_vm._e(),(!_vm.avatar)?_c('UserIcon',{attrs:{"fill":_vm.userIconFill}}):_vm._e(),_c('p',{staticClass:"header__username my-0 ml-1 mr-3"},[_vm._v(" "+_vm._s(_vm.username)+" ")])],1)],1):_vm._e(),_c('li',{staticClass:"mt-3"},[_c('LanguageDropdown')],1),_c('li',{staticClass:"mt-3 fw-bold text-primary",on:{"click":_vm.logout}},[(_vm.getLoadings.logout)?_c('b-spinner',{attrs:{"small":"","label":"Spinning","variant":"primary"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("logout"))+" ")],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }