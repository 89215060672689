<template>
  <div>
    <UserHeader
      :links="currentLink"
      :loginBtnText="
        routeType == 'user' || routeType === 'general' ? $t('header.login') : $t('login')
      "
      :username="user ? user.name : 'anonymous'"
      :officeName="user ? user.officeName : 'anonymous'"
      :avatar="user && user.logo"
    />
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import headerMixin from "@/mixins/headerMixin";
import UserHeader from "./UserHeader.vue";

export default {
  name: "UserLayout",
  mixins: [headerMixin],
  components: {
    UserHeader,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["user", "getUserType", "isAuth"]),
    // setting header links based on whether current route is for user or broker
    userLinks() {
      const localePart = this.$i18n.locale === "en" ? "en/" : "";
      if (this.isAuth) {
        return [
          {
            to: "/listings/saved",
            name: this.$t("savedListings"),
          },
          {
            to: this.profileRoute,
            name: this.$t("myProfile"),
          },
          {
            to: "/messages",
            name: this.$t("messages"),
            label: "message",
          },
        ];
      }
      return [
        {
          to: "/listings?page=1",
          name: this.$t("listings"),
        },
        {
          to: `https://info.eerlijktehuur.nl/${localePart}faq`,
          name: this.$t("header.faq"),
          external: true,
        },
        {
          to: `https://info.eerlijktehuur.nl/${localePart}over-ons`,
          name: this.$t("header.about"),
          external: true,
        },
      ];
    },
    currentLink() {
      return this.userLinks;
    },
    routeType() {
      return this.$route.meta.type;
    },
  },
  mounted() {
    if (this.user.locale == "nl") {
      !(function (e, t, n) {
        function a() {
          var e = t.getElementsByTagName("script")[0],
            n = t.createElement("script");
          (n.type = "text/javascript"),
            (n.async = !0),
            (n.src = "https://beacon-v2.helpscout.net"),
            e.parentNode.insertBefore(n, e);
        }
        if (
          ((e.Beacon = n = function (t, n, a) {
            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
          }),
          (n.readyQueue = []),
          "complete" === t.readyState)
        )
          return a();
        e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1);
      })(window, document, window.Beacon || function () {});
      window.Beacon("init", "8241c080-1de3-40aa-828e-fdc573f8e205");
    } else {
      !(function (e, t, n) {
        function a() {
          var e = t.getElementsByTagName("script")[0],
            n = t.createElement("script");
          (n.type = "text/javascript"),
            (n.async = !0),
            (n.src = "https://beacon-v2.helpscout.net"),
            e.parentNode.insertBefore(n, e);
        }
        if (
          ((e.Beacon = n = function (t, n, a) {
            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
          }),
          (n.readyQueue = []),
          "complete" === t.readyState)
        )
          return a();
        e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1);
      })(window, document, window.Beacon || function () {});
      window.Beacon("init", "d2a84eaa-9f6b-4f52-bdd7-82bb53ee37bf");
    }
  },
};
</script>

<style lang="scss" scoped></style>
