<template>
  <header class="header">
    <div class="header__container d-lg-flex align-items-center d-none h-100 user">
      <RouterLink class="align-self-center" to="/">
        <img class="img-fluid" :src="logo" alt="logo" />
      </RouterLink>
      <!-- links for tannent -->
      <div class="d-flex h-100">
        <ul
          class="header__linksContainer justify-content-center d-flex mb-0 pl-0 ml-0 user"
        >
          <li
            class="d-flex align-items-center position-relative"
            id="tooltip-button-1"
            v-if="tenantsVerified"
          >
            <router-link
              class="header__linksContainer__links pl-0 px-4 text-center user"
              to="/listings?page=1"
            >
              <span class="header__linksContainer__links__link">{{
                $t("Listings")
              }}</span>
            </router-link>
          </li>
          <li
            class="d-flex align-items-center position-relative"
            id="tooltip-button-1"
            v-else
          >
            <span class="text-gray-inactive fw-700">
              <i class="fa-solid fa-lock mr-1"></i>
              {{ $t("Listings") }}</span
            >
          </li>
          <li
            class="d-flex align-items-center position-relative"
            v-for="link in userLinks"
            :key="link.name"
          >
            <a
              v-if="link.external"
              class="header__linksContainer__links px-4 text-center user"
              :href="link.to"
              target="_blank"
            >
              <span class="header__linksContainer__links__link">{{ link.name }}</span>
            </a>
            <router-link
              v-else
              class="header__linksContainer__links pl-0 px-4 text-center user"
              :to="link.to"
            >
              <span class="header__linksContainer__links__link">{{ link.name }}</span>
            </router-link>
            <span
              class="hasMessages"
              v-if="link.label == 'message' && user && user.hasMessages"
            ></span>
          </li>
        </ul>
        <ul class="mb-0 d-flex align-items-center ml-5">
          <li class="d-flex align-items-center position-relative" v-if="currentLang">
            <LanguageDropdown />
          </li>
          <li class="ml-4">
            <a href="javascript:void()" @click="logout">
              <exit />
            </a>
          </li>
        </ul>

        <b-tooltip target="tooltip-button-1" placement="right" v-if="!tenantsVerified">
          All tenants need to be verified first
        </b-tooltip>
      </div>
    </div>
    <!--header  mobile menu -->
    <div class="header__containerMobile d-flex d-lg-none align-items-center user">
      <img class="img-fluid" :src="logo" alt="" />
      <button class="p-0 btn d-block d-lg-none" v-b-toggle.sidebar-1>
        <BurgerMenuIcon />
      </button>
      <b-sidebar
        id="sidebar-1"
        bg-variant="white"
        shadow
        backdrop-variant="dark"
        backdrop
        no-header
      >
        <div class="px-3 py-2">
          <a
            href="/"
            class="d-flex align-items-center pb-3 my-3 link-dark text-decoration-none border-bottom"
          >
            <img class="img-fluid" :src="logo" alt="logo" />
          </a>

          <div>
            <ul>
              <li class="d-flex mb-3" v-if="tenantsVerified">
                <router-link to="/listings?page=1" class="fw-bold text-black">
                  {{ $t("Listings") }}
                </router-link>
              </li>
              <li class="mb-3" id="tooltip-button-1" v-else>
                <div class="text-gray-inactive fw-700">
                  <i class="fa-solid fa-lock mr-1"></i>
                  {{ $t("Listings") }}
                </div>

                <div class="badge badge-secondary ml-2">
                  All tenants need to be verified first
                </div>
              </li>
              <li class="mb-3">
                <router-link to="/listings/saved" class="fw-bold text-black">
                  Saved Listings
                </router-link>
              </li>
              <li class="mb-3">
                <router-link to="/user/profile" class="fw-bold text-black">
                  My Profile
                </router-link>
              </li>
              <li class="mb-3">
                <router-link to="/messages" class="fw-bold text-black">
                  Messages
                </router-link>
              </li>
              <li class="mb-3">
                <LanguageDropdown />
              </li>
              <li class="mb-3">
                <a href="javascript:void()" @click="logout" class="fw-bold text-black">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </b-sidebar>
      <!-- <HeaderMobileMenu
        :links="links"
        :showMenu="showMenu"
        :loginBtnText="loginBtnText"
        @close="showMenu = false"
        :username="username"
      /> -->
    </div>
  </header>
</template>

<script>
import BurgerMenuIcon from "@/components/svg/BurgerIcon.vue";

import headerMixin from "@/mixins/headerMixin";
import { mapGetters } from "vuex";
import LanguageDropdown from "../components/common/LanguageDropdown.vue";

export default {
  mixins: [headerMixin],
  components: {
    BurgerMenuIcon,
    LanguageDropdown,
  },
  data() {
    return {
      showMenu: false,
      showMenuDesktop: false,

      enFlag: require("../assets/images/common/en.jpg"),
      nlFlag: require("../assets/images/common/nl.png"),
      currentLang: "",
      show: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["tenantsVerified"]),
    getFlag() {
      return this.getLocale === "en" ? this.nlFlag : this.enFlag;
    },
    desktopLinks() {
      return [
        {
          to: this.profileRoute,
          name: "userprofile",
          component: "UserIcon",
          text: this.$t("myProfile2"),
          type: "link",
          class: "",
        },
        {
          to: "",
          name: "switchLanguage",
          component: "UserIcon",
          text: "",
          type: "switch_locale",
          class: "",
        },
        {
          to: "https://help.eerlijktehuur.nl",
          name: "help",
          component: "questionmark",
          text: this.$t("header.help"),
          type: "link",
          class: "",
        },
        {
          to: null,
          name: "logout",
          component: "questionmark",
          text: this.$t("logout"),
          type: "logoutbtn",
          class: "",
        },
      ];
    },
    userLinks() {
      return [
        {
          to: "/listings/saved",
          name: this.$t("savedListings"),
        },
        {
          to: this.profileRoute,
          name: this.$t("myProfile"),
        },
        {
          to: "/messages",
          name: this.$t("messages"),
          label: "message",
        },
      ];
    },
  },
  methods: {
    closeMenu(e) {
      if (!this.$el.contains(e.target)) {
        this.showMenuDesktop = false;
        this.showMenu = false;
      }
    },
    switchLanguage(language) {
      this.currentLang = language;
    },
  },
  mounted() {
    document.addEventListener("click", this.closeMenu);
    this.currentLang = window.Weglot?.getCurrentLang() || "en";
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeMenu);
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 80px;
  background: white;

  &__linksContainer {
    &__links {
      &__link {
        @include font(1rem, $dark, 700);
        // @include lg {
        //   font-size: 1rem;
        // }
      }
    }

    &__links.broker {
      padding-right: 7.5rem;
    }

    a {
      &::after {
        @include pseudo;
        height: 3px;
        width: 100%;
        left: 0;
        bottom: 0px;
        background: $primary;
        transform: scaleX(0);
        @include transition(transform 0.3s ease-in-out);
      }

      &:hover,
      &.router-link-active {
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }
  &__containerMobile {
    padding: 14px 19px 14px 55px;
    width: 100%;
    justify-content: space-between;

    @include lg {
      padding: 14px 19px;
    }
  }

  &__containerDesktop {
    background: $white;
    width: 272px;
    padding: 20px;

    top: 50px;
    right: 0px;

    border: 1.5px solid #dedede;
    box-shadow: 0px 10px 10px rgba(16, 10, 85, 0.1);
    border-radius: 8px;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    z-index: 0;
    transition: 0.3s all;

    &__link {
      @include font(1rem, #9b9eab, 500);
      vertical-align: center;
    }

    &__link:hover {
      color: #212529;
    }
  }

  &__containerDesktop.broker {
    top: 60px;
  }

  &__desktopBurgerBtn.broker {
    background: #ffffff;
    border: 1.5px solid #e4e4e4;
    border-radius: 8px;

    padding: 12px 20px !important;
    height: 50px;
    margin-left: 30px;
  }

  &__containerDesktop.show {
    z-index: 99999;
    opacity: 1;
    user-select: auto;
    pointer-events: auto;
  }

  &__container {
    padding: 0px 55px 0px 55px;
    width: 100%;

    @include lg {
      padding: 14px 19px;
    }

    &__addListingBtn.btn {
      padding: 0.8rem 1.3rem !important;
    }
  }

  &__container.user {
    justify-content: space-between;
  }

  &__container.broker {
    justify-content: space-between;
  }

  &__username {
    @include font(1rem, #9b9eab, 500);
    vertical-align: center;
  }

  &__btn {
    @include lg {
      font-size: 1rem;
    }
  }

  &__languange-btn {
    margin: 0 1rem;
  }
}

.nl {
  .header__linksContainer {
    &.user {
      max-width: unset;
    }
  }
}

.header.not-login {
  background: #eff4fb;
}

.header__notLoggin__link {
  @include font(1rem, #000000, 500);
}
.hasMessages {
  width: 8px;
  height: 8px;
  background: #0a9250;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 5px;
}
</style>
