// import axiosInstance from "./axios/axios";

export const sendRequest = async (payload) => {
  const { method, url, body = null, options = {}, multipart = false } = payload;
  console.log("method, url, body , options, multipart", method, url, body, options, multipart)
  let formData = body;

  if (multipart) {
    if (method !== "GET" && method !== "DELETE") {
      if (!body) throw new Error("No request body");
      formData = new FormData();
      for (let key in body) {
        if (body[key] == null) continue;
        if (typeof body[key] === "string" && !body[key].length) continue
        if (
          typeof body[key] === "object" &&
          !Array.isArray(body[key]) &&
          body[key] != null &&
          !(body[key] instanceof File)
        ) {
          for (let key2 in body[key]) {
            formData.append(key, body[key][key2]);
          }
        } else if (Array.isArray(body[key])) {
          for (let key2 of body[key]) {
            formData.append(key, body[key][key2]);
          }
        } else {
          formData.append(key, body[key]);
        }
      }
    }
  }

  try {
    let res = null;
    // if (method.toLowerCase() === "delete" || method.toLowerCase() === "get") {
    //   res = await axiosInstance[method.toLowerCase()](url, options);
    // } else {
    //   res = await axiosInstance[method.toLowerCase()](url, formData, options);
    // }
    return res.data;
  } catch (error) {
    let err = "";
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.error) err = error.response.data.error;
        else if (error.response.data.message) err = error.response.data.message;
        else {
          err = error.response.data;
        }
      }
    } else {
      err = error.message;
    }

    throw new Error(err);
  }
};
