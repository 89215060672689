<template>
  <b-sidebar
    id="mobile-menu"
    class="header__mobileMenu"
    bg-variant="white"
    shadow
    backdrop-variant="dark"
    :visible="showMenu"
    backdrop
    @hidden="$emit('close', false)"
    body-class="header__mobileMenu__body"
  >
    <div class="">
      <img class="img-fluid" :src="logo" alt="logo" />
    </div>

    <ul class="header__mobileMenu__linksContainer p-0">
      <li class="position-relative pt-3" v-for="link in links" :key="link.name">
        <a
          v-if="link.external"
          class="header__mobileMenu__linksContainer__links__link"
          :class="{
            broker: getUserType == 'broker',
            user: getUserType === 'user',
          }"
          :href="link.to"
          target="_blank"
        >
          <span class="header__linksContainer__links__link">{{ link.name }}</span>
        </a>
        <router-link
          v-else
          class="header__mobileMenu__linksContainer__links__link"
          :to="link.to"
          :class="{
            broker: getUserType == 'broker',
            user: getUserType === 'user',
          }"
        >
          <span class="header__linksContainer__links__link">{{ link.name }}</span>
        </router-link>
      </li>
      <li class="pt-3" v-if="username && getUserType === 'broker'">
        <routerLink :to="profileRoute" class="d-flex text-dark fw-bold">
          <img
            v-if="avatar"
            :src="avatar"
            class="rounded-circle"
            width="24"
            height="24"
          />

          <UserIcon :fill="userIconFill" v-if="!avatar" />
          <p class="header__username my-0 ml-1 mr-3">
            {{ username }}
          </p>
        </routerLink>
      </li>
      <li class="mt-3">
        <LanguageDropdown />
      </li>
      <li class="mt-3 fw-bold text-primary" @click="logout">
        <b-spinner
          v-if="getLoadings.logout"
          small
          label="Spinning"
          variant="primary"
        ></b-spinner>
        {{ $t("logout") }}
      </li>
    </ul>

    <!-- <routerLink to="/user/login" v-else class="btn btn-primary header__btn mt-3">
      {{ loginBtnText }}
    </routerLink> -->
  </b-sidebar>
</template>

<script>
import headerMixin from "@/mixins/headerMixin";
import LanguageDropdown from "../common/LanguageDropdown.vue";
export default {
    mixins: [headerMixin],
    props: ["showMenu"],
    data() {
        return {
            enFlag: require("../../assets/images/common/en.jpg"),
            nlFlag: require("../../assets/images/common/nl.png"),
        };
    },
    computed: {
        getFlag() {
            return this.getLocale === "en" ? this.enFlag : this.nlFlag;
        },
    },
    components: { LanguageDropdown }
};
</script>

<style lang="scss">
.header {
  background: white;

  &__mobileMenu {
    &__body {
      @include lg {
        padding: 14px 19px;
      }
      @include md {
        padding: 0px 20px 0px 20px;
      }

      &__btn {
        @include lg {
          font-size: 1rem !important;
        }
        @include md {
          padding: 0.8rem 3.3rem !important;
        }
      }
    }

    &__linksContainer {
      flex-basis: 100%;
      max-width: 100%;
      padding-top: 20px;

      &__links {
        &__link {
          @include font(1.25rem, $dark, 700);
          @include lg {
            font-size: 1rem;
          }
        }
      }
      :hover {
        text-decoration: none !important;
        color: $primary;
      }
    }
    &__userButton {
      box-shadow: 0px 2px 3px -1px rgba($color: #e1e1e1, $alpha: 1) !important;
      padding: 14px !important;
      border: 1px solid #e1e1e1 !important;
      border-radius: 5px !important;
      margin: 10px 0;
      &__username {
        @include font(1rem, $dark2, 700);
        margin-left: 10px;
      }
    }
  }
}
</style>
