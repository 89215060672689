// import router from "@/router/index";
const state = () => ({
    messages: { data: [] },
    conversations: [],
    listings: [],
    active_message: null,
    message_text: "",
    message_alert: false,
});

const getters = {
    messages(state) {
        return state.messages;
    },
    activeConversation(state) {
        let index = state.conversations.findIndex(
            (x) => x.message_id == state.active_message?.id
        );
        return index != -1 ? state.conversations[index].conversation : null;
    },
    activeMessage(state) {
        console.log("activeConversation");
        return state.active_message;
    },
    activeListing(state) {
        let index = state.listings.findIndex(
            (x) => x.id == state.active_message?.listing?.id
        );
        return index != -1 ? state.listings[index] : null;
    },
    conversations(state) {
        return state.conversations;
    },
    messageText(state) {
        return state.message_text;
    },
    messageAlert(state) {
        return state.message_alert;
    },
};

const mutations = {
    setMessages(state, messages) {
        state.messages = messages;
    },
    setMessage(state, message) {
        let index = state.messages.data.findIndex((x) => x.id == message.id);
        if (index != -1) {
            state.messages.data[index] = message;
        } else {
            state.messages.data.splice(0, 0, message);
        }
    },
    setActiveMessage(state, message) {
        state.active_message = message;
    },
    setConversation(state, { conversation, message_id }) {
        let index = state.conversations.findIndex(
            (x) => x.message_id == message_id
        );
        if (index != -1) {
            state.conversations[index] = conversation;
        } else {
            state.conversations.push({
                message_id: message_id,
                conversation: conversation,
            });
        }
        // state.conversations[message_id] = conversation;
    },
    addConversation(state, { conversation, message_id }) {
        let index = state.conversations.findIndex(
            (x) => x.message_id == message_id
        );
        if (index != -1) {
            state.conversations[index].conversation.data.push(conversation);
        }
    },
    addListing(state, listing) {
        let index = state.listings.findIndex(
            (x) => x.id == state.active_message?.listing?.id
        );
        if (index == -1) {
            state.listings.push(listing);
        }
    },
    setMessageText(state, messageText) {
        state.message_text = messageText;
        let prohibited = ["@", ".com", ".nl"];
        state.message_alert = prohibited.find((x) => messageText.includes(x));
    },
};

const actions = {
    async getMessages({ commit, dispatch }, { type = "user" }) {
        const url = `/${type}/messages`;
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "messages", state: true },
                { root: true }
            );

            dispatch(
                "loadingError/setError",
                { type: "messages", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(url);

            commit("setMessages", data.messages);
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "messages", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "messages", state: false },
                { root: true }
            );
        }
    },

    selectMessage({ dispatch, state, commit, rootState }, message) {
        commit("setActiveMessage", message);
        let userType = rootState.auth.userType;
        if (userType == "user") {
            message.user_seen_at = new Date();
        } else if (userType == "broker") {
            message.broker_seen_at = new Date();
        }
        if (!state.conversations[message.id]) {
            dispatch("getConversation", { message_id: message.id });
        } else {
            dispatch("scrollBottomChat");
        }
    },

    async getConversation({ commit, dispatch, rootState }, { message_id }) {
        try {
            let userType = rootState.auth.userType;
            dispatch(
                "loadingError/setLoading",
                { type: "conversation", state: true },
                { root: true }
            );

            dispatch(
                "loadingError/setError",
                { type: "conversation", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/${userType}/messages/${message_id}/conversation`
            );

            commit("setConversation", {
                conversation: data.conversation,
                message_id,
            });
            // commit("setMessage", data.message);
            commit("addListing", data.message.listing);
            dispatch("scrollBottomChat");
            dispatch("user/getUser", {}, { root: true });
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "conversation", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "conversation", state: false },
                { root: true }
            );
        }
    },
    async sendMessage({ commit, dispatch, rootState }, payload) {
        try {
            let userType = rootState.auth.userType;
            let to = userType == "broker" ? "user" : "broker";

            if (payload.type != "text") {
                let formData = new FormData();
                formData.append("file", payload.file);
                formData.append("type", payload.type);
                formData.append("listing_id", payload.listing_id);
                formData.append("user_id", payload.user_id);
                payload = formData;
            }

            dispatch(
                "loadingError/setLoading",
                { type: "send_message", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "send_message", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(
                `/${userType}/messages/send/${to}`,
                payload
            );
            commit("addConversation", {
                conversation: data.conversation,
                message_id: data.message.id,
            });
            dispatch("scrollBottomChat");
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "conversation", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "send_message", state: false },
                { root: true }
            );
        }
    },
    scrollBottomChat() {
        setTimeout(async () => {
            let elem = document.getElementsByClassName("conversation")[0];
            if (!elem) {
                return;
            }
            for (let i = elem.scrollTop; i <= elem.scrollHeight; ) {
                elem.scrollTop = i;
                await new Promise((r) => setTimeout(r, 10));
                i += 20;
            }
        }, 500);
    },
};

export const messages = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
