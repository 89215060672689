import UserLayout from "@/layouts/UserLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import SimpleLogoLayout from "../layouts/SimpleLogoLayout.vue";

const routes = [
    {
        path: "/user/login",
        name: "UserLogin",
        component: () => import("../views/User/Login.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            // protected: true,
        },
    },
    {
        path: "/user/register",
        name: "UserRegister",
        component: () => import("../views/User/Register.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            // protected: true,
        },
    },
    {
        path: "/listings",
        name: "Listings",
        component: () => import("../views/Listings.vue"),
        meta: {
            layout: UserLayout,
            type: "general",
            protected: true,
        },
    },
    {
        path: "/listings/requested",
        name: "ListingRequested",
        component: () => import("../views/Requested.vue"),
        meta: {
            layout: UserLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/listings/saved",
        name: "ListingsSaved",
        component: () => import("../views/SavedListings.vue"),
        meta: {
            layout: UserLayout,
            type: "user",
            protected: true,
        },
    },

    {
        path: "/listings/:listingId/schedule",
        name: "Schedule",
        component: () => import("../views/Schedule.vue"),
        meta: {
            layout: UserLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/profile",
        name: "MyProfile",
        component: () => import("../views/User/UserProfile.vue"),
        meta: {
            layout: UserLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/start/1",
        name: "PaymentStep1",
        component: () => import("../views/User/payments/Step1.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/start/2",
        name: "PaymentStep1",
        component: () => import("../views/User/payments/Step2.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/start/3",
        name: "PaymentStep2",
        component: () => import("../views/User/payments/Step3.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/payments/success",
        name: "PaymentSuccess",
        component: () => import("../views/User/payments/Success.vue"),
        meta: {
            layout: SimpleLogoLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/selection",
        name: "ExpatSelection",
        component: () => import("../views/User/Onboarding/Step1.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/selection/:tenant_id",
        name: "ExpatSelection",
        component: () => import("../views/User/Onboarding/Step1.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/income/:step/:tenant_id",
        name: "TenantIncome",
        component: () => import("../views/User/Onboarding/Income.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/qii-vs-digid/:tenant_id",
        name: "QiiVsDigid",
        component: () => import("../views/User/Onboarding/QiiDigid.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/digid/:tenant_id",
        name: "Digid",
        component: () => import("../views/User/Onboarding/Digid/Index.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/digid/:tenant_id/failed",
        name: "DigidFailed",
        component: () =>
            import("../views/User/Onboarding/Digid/FailedDigid.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/digid/uwv/:tenant_id",
        name: "DigidUWV",
        component: () => import("../views/User/Onboarding/Digid/Index.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/digid/uwv/:tenant_id/failed",
        name: "DigidUWVFailed",
        component: () =>
            import("../views/User/Onboarding/Digid/FailedDigid.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/onboarding/success",
        name: "OnboardingSuccess",
        component: () => import("../views/User/Onboarding/Success.vue"),
        meta: {
            layout: SimpleLogoLayout,
            type: "user",
            protected: true,
        },
    },
    {
        path: "/user/qiiSteps",
        name: "QiiSteps",
        component: () => import("../views/User/Qii/Steps.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
        children: [
            {
                path: "final",
                name: "QiiFinal",
                component: () => import("../views/User/Qii/Step5.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: ":step/:tenant_id",
                name: "QiiStep",
                component: () => import("../views/User/Qii/Step.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
        ],
    },

    {
        path: "/user/flow",
        name: "ExpatSteps",
        component: () => import("../views/User/Expat/Steps.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
        children: [
            {
                path: "fallback/:tenant_id/2",
                name: "ExpatStepFallbackStep2",
                component: () =>
                    import("../views/User/Expat/FallbackStep2.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "fallback/:tenant_id",
                name: "ExpatStepFallback",
                component: () => import("../views/User/Expat/Fallback.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "1/:tenant_id",
                name: "ExpatStep1",
                component: () => import("../views/User/Expat/Step1.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "2/:tenant_id",
                name: "ExpatStep2",
                component: () => import("../views/User/Expat/Step2.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "3/:tenant_id",
                name: "ExpatStep3",
                component: () => import("../views/User/Expat/Step3.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "4/:tenant_id",
                name: "ExpatStep4",
                component: () => import("../views/User/Expat/Step4.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "5/:tenant_id",
                name: "ExpatStep5",
                component: () => import("../views/User/Expat/Step5.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "6/:tenant_id",
                name: "ExpatStep6",
                component: () => import("../views/User/Expat/Step6.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "6.1/:tenant_id",
                name: "ExpatStep6.1",
                component: () => import("../views/User/Expat/Step6.1.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "6.1/:tenant_id",
                name: "ExpatStep6",
                component: () => import("../views/User/Expat/Step6.1.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "7/:tenant_id",
                name: "ExpatStep7",
                component: () => import("../views/User/Expat/Step7.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "8/:tenant_id",
                name: "ExpatStep8",
                component: () => import("../views/User/Expat/Step8.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "9/:tenant_id",
                name: "ExpatStep9",
                component: () => import("../views/User/Expat/Step9.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "10/:tenant_id",
                name: "ExpatStep10",
                component: () => import("../views/User/Expat/Step10.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
            {
                path: "11/:tenant_id",
                name: "ExpatStep11",
                component: () => import("../views/User/Expat/Step11.vue"),
                meta: {
                    layout: AuthLayout,
                    type: "user",
                    protected: true,
                },
            },
        ],
    },
    {
        path: "/user/entrepreneur/:tenant_id",
        name: "EntrepreneurDocumentUpload",
        component: () => import("../views/User/Entrepreneur/Step2.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
        },
    },
    {
        path: "/listings/:listing_id/rent-log",
        name: "ListingRentLog",
        component: () => import("../views/User/Listings/RentLog.vue"),
        meta: {
            layout: UserLayout,
            type: "user",
            protected: true,
        },
    },

    {
        path: "/user/guarantor/1",
        name: "GuarantorStep1",
        component: () => import("../views/User/Guarantor/Step1.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },

    {
        path: "/user/guarantor/1/:tenant_id",
        name: "GuarantorStep1",
        component: () => import("../views/User/Guarantor/Step1.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },

    {
        path: "/user/guarantor/2",
        name: "GuarantorStep1",
        component: () => import("../views/User/Guarantor/Step2.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },

    {
        path: "/user/guarantor/2/:tenant_id",
        name: "GuarantorStep1",
        component: () => import("../views/User/Guarantor/Step2.vue"),
        meta: {
            layout: AuthLayout,
            type: "user",
            protected: true,
        },
    },
];

export default routes;
