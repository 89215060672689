import Flag from "@/components/svg/Flag.vue";
import { mapActions, mapGetters } from "vuex";
import UserIcon from "@/components/svg/user.vue";
import questionmark from "@/components/svg/questionmark.vue";
import exit from "@/components/svg/exit.vue";
import Plus from "@/components/svg/Plus.vue";
import ChevronDown from "@/components/svg/ChevronDown.vue";
import edit from "@/components/svg/edit.vue";
export default {
    props: ["links", "loginBtnText", "username", "avatar"],
    components: {
        Flag,
        UserIcon,
        questionmark,
        exit,
        Plus,
        ChevronDown,
        edit,
    },
    data() {
        return {
            logo: require("../assets/images/common/logo-ETH.svg"),
        };
    },
    computed: {
        ...mapGetters("auth", [
            "isAuth",
            "getUserType",
            "userType",
            "profileCompleted",
            "user",
        ]),
        ...mapGetters("loadingError", ["getLoadings"]),
        ...mapGetters(["getLocale", "locale"]),
        routeType() {
            return this.$route.meta.type;
        },
        profileRoute() {
            return this.getUserType === "broker"
                ? "/broker/profile"
                : this.profileCompleted
                ? "/user/profile"
                : "/user/qiiSteps/1";
        },
        userIconFill() {
            return this.getUserType === "broker" ? "#0A9250" : "#fff";
        },
    },
    methods: {
        ...mapActions("auth", ["logout"]),
        ...mapActions(["setLocale"]),
        switchLocale() {
            const locale = this.getLocale === "en" ? "nl" : "en";
            this.setLocale(locale);
            this.$i18n.locale = locale;
            // localize(locale);

            window.axios.get(`/${this.getUserType}/set-locale/${locale}`);
            return document.body.classList.toggle("nl");
        },
    },
};
