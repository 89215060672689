import BrokerLayout from "@/layouts/BrokerLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

const routes = [
    {
        path: "/broker/login",
        name: "BrokerLogin",
        component: () => import("../views/Broker/Login.vue"),
        meta: {
            layout: AuthLayout,
            type: "broker",
        },
    },
    {
        path: "/broker/register",
        name: "BrokerRegister",
        component: () => import("../views/Broker/Register.vue"),
        meta: {
            layout: AuthLayout,
            type: "broker",
        },
    },
    {
        path: "/broker/profile",
        name: "Profile",
        component: () => import("../views/Broker/Profile.vue"),
        meta: {
            layout: BrokerLayout,
            type: "broker",
            protected: true,
            backText: "my-listings",
            backTo: "/broker/listings",
        },
    },
    {
        path: "/broker/listings",
        name: "BrokerListings",
        component: () => import("../views/Broker/Listings/Index.vue"),
        meta: {
            layout: MainLayout,
            type: "broker",
            protected: true,
        },
    },
    {
        path: "/broker/listings/listview",
        name: "BrokerListingsListview",
        component: () => import("../views/Broker/Listings/Index.vue"),
        meta: {
            layout: MainLayout,
            type: "broker",
            protected: true,
        },
    },
    {
        path: "/broker/addListings/:listingId/:mode",
        name: "BrokerEditListing",
        component: () =>
            import("../views/Broker/AddListing/BrokerAddListing.vue"),
        meta: {
            backText: "my-listings",
            backTo: "/broker/listings",
            layout: BrokerLayout,
            type: "broker",
            protected: true,
        },
    },
    {
        path: "/broker/addListings",
        name: "BrokerAddListing",
        component: () =>
            import("../views/Broker/AddListing/BrokerAddListing.vue"),
        meta: {
            backText: "my-listings",
            backTo: "/broker/listings",
            layout: BrokerLayout,
            type: "broker",
            protected: true,
        },
    },
    {
        path: "/broker/listings/:listingId",
        name: "RequestsTable",
        component: () => import("../views/Broker/RequestsTable.vue"),

        meta: {
            layout: MainLayout,
            type: "broker",
            protected: true,
        },
    },
    {
        path: "/broker/listings-assign/:listingId",
        name: "AssigningTable",
        component: () => import("../views/Broker/AssigningTable.vue"),

        meta: {
            layout: MainLayout,
            type: "broker",
            protected: true,
        },
    },
    {
        path: "/broker/listings/:listingId/assigned",
        name: "ListingAssigned",
        component: () => import("../views/Broker/Listings/Assigned.vue"),

        meta: {
            layout: MainLayout,
            type: "broker",
            protected: true,
        },
    },
    {
        path: "/broker/listings/:listingId/agreement",
        name: "ListingAgreement",
        component: () => import("../views/Broker/Listings/ListingAgreement.vue"),

        meta: {
            layout: MainLayout,
            type: "broker",
            protected: true,
        },
    },
    {
        path: "/broker/listings/:listingId/assigned/tenant/:tenant_id",
        name: "ListingAssignedTenantDetail",
        component: () => import("../views/Broker/Listings/TenantDetail.vue"),

        meta: {
            layout: MainLayout,
            type: "broker",
            protected: true,
        },
    },
    {
        path: "/broker/calendar",
        name: "Calendar",
        component: () => import("../views/Broker/Calendar/Index.vue"),

        meta: {
            layout: MainLayout,
            type: "broker",
            protected: true,
        },
    },

    {
        path: "/office/:office_name",
        name: "BrokerPublicProfile",
        component: () => import("../views/Broker/PublicProfile.vue"),
        meta: {
            layout: MainLayout,
            type: "general",
        },
    },
];

export default routes;
