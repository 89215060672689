<template>
  <div>
    <div class="w-100 d-flex align-items-center brokerheader position-relative">
      <router-link v-if="$route.meta.backTo" :to="$route.meta.backTo">
        <BackIcon />
        <span class="brokerheader__text pl-2">
          {{ $t("backTo") }} {{ $t($route.meta.backText) }}
        </span>
      </router-link>
      <div class="text-center mx-auto brokerheader__logo">
        <RouterLink to="/"
          ><img
            class="img-fluid"
            :src="require('@/assets/images/common/logo-ETH.svg')"
            alt="logo"
        /></RouterLink>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import BackIcon from "@/components/svg/left-arrow.vue";

export default {
  components: {
    BackIcon,
  },
};
</script>

<style lang="scss" scoped>
.brokerheader {
  padding: 0px 52px;
  background: white;
  border-bottom: 2px solid $gray2;
  height: 94px;
  @include xs {
    flex-direction: column-reverse;
    padding: 32px 15px;
    height: auto;
  }
  &__text {
    color: #7f8494 !important;
    font-size: 0.875rem;
    font-weight: 700;
  }
  &__text::hover {
    color: #7f8494 !important;
  }
  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include sm {
      left: auto;
      right: 52px;
      transform: translate(0%, -50%);
    }
    @include xs {
      position: static;
      transform: translate(0, 0);
      padding-bottom: 15px;
    }
  }
}
</style>
