import { extend } from "vee-validate";

import {
  required,
  email,
  numeric,
  image,
  ext,
  between,
  alpha,
  alpha_dash,
  max,
  min,
  confirmed,
  double,
  regex,
  alpha_num,
  is_not,
  alpha_spaces,
  length,
  oneOf,
  max_value,
  required_if
} from "vee-validate/dist/rules";

export default () => {
  return [
    extend("email", { ...email}),
    extend("numeric", { ...numeric }),
    extend("alpha_num", alpha_num),
    extend("alpha_spaces", alpha_spaces),
    extend("ext", ext),
    extend("is_not", is_not),
    extend("image", image),

    extend("required", {
      ...required,

    }),
    extend("required_if", {
      ...required_if,

    }),
    extend("length", length),
    extend("between", between),
    extend("max", {...max}),
    extend("min", {...min}),
    extend("alpha", alpha),
    extend("confirmed", {...confirmed}),
    extend("isValidPhoneNumber", (value) => {
      const pattern = new RegExp(/^[+ 0-9]{10,13}$/);
      return pattern.test(value);
    }),
    extend("minArrayLength", (value,args) => value.length >= args[0]),
    extend("maxArrayLength", (value,args) => {
      return value.length >= args[0] ? true : `Maximum ${args[0]} items are allowed`
    }),
    extend("alpha_dash", alpha_dash),
    extend("double", double),
    extend("regex", regex),
    extend('oneOf',oneOf),
    extend('maxValue',max_value)
  ];
};
