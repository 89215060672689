// import router from "@/router/index";
const state = () => ({
    code: null,
});

const getters = {
    code(state) {
        return state.code;
    },
};

const mutations = {
    setCode(state, payload) {
        state.code = payload;
    },
};

const actions = {
    async getCode({ commit, dispatch }, tenant_id) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "qii", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "qii", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/user/tenant/${tenant_id}/qii/code`
            );
            commit("setCode", data.authentication_code);
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "qii", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "qii", state: false },
                { root: true }
            );
        }
    },
    async setQiiProfile({ commit, dispatch }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "qii", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "qii", state: "" },
                { root: true }
            );
            const { data } = await window.axios.post("/user/qii/set-profile");
            commit("auth/setUserData", data.data, { root: true });
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "qii", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "qii", state: false },
                { root: true }
            );
        }
    },
};

export const qiiModule = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
