<template>
  <div>
    <div class="w-100 landlordHeader d-flex align-items-center justify-content-between">
      <RouterLink to="/admin" class="align-self-center">
        <img
          class="img-fluid"
          :src="require('@/assets/images/common/logo-ETH.svg')"
          alt="logo"
        />
      </RouterLink>
      <a href="#">
        <BurgerMenuIcon />
      </a>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BurgerMenuIcon from "@/components/svg/BurgerIcon.vue";

export default {
  components: {
    BurgerMenuIcon,
  },
  data() {
    return {
      showMenu: true,
    };
  },
  mounted() {
    document.addEventListener("click", this.closeMenu);
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    closeMenu(e) {
      if (!this.$el.contains(e.target)) {
        this.showMenuDesktop = false;
        this.showMenu = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.landlordHeader {
  padding: 0px 52px;
  background: white;
  border-bottom: 2px solid $gray2;
  height: 80px;
  padding: 0px 55px 0px 55px;

  @include xs {
    flex-direction: column-reverse;
    padding: 32px 15px;
    height: auto;
  }

  &__text {
    color: #7f8494 !important;
    font-size: 0.875rem;
    font-weight: 700;
  }

  &__text::hover {
    color: #7f8494 !important;
  }

  &__logo {
    @include sm {
      left: auto;
      right: 52px;
      transform: translate(0%, -50%);
    }

    @include xs {
      position: static;
      transform: translate(0, 0);
      padding-bottom: 15px;
    }
  }
}

.container {
  height: calc(100vh - 80px);
}
</style>
