import { sendRequest } from "@/utils/sendRequest";
const state = () => ({
    savedListings: [],
    user: null,
});

const getters = {
    getSavedListings(state) {
        return state.savedListings;
    },
    user(state) {
        return state.user;
    },
};
const mutations = {
    setSavedListings(state, payload) {
        state.savedListings = payload;
    },
    setUser(state, user) {
        state.user = user;
    },
};

const actions = {
    async getUser({ dispatch, rootState, commit }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "get_user", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "get_user", state: "" },
                { root: true }
            );
            let userType = rootState.auth.userType;

            const { data } = await window.axios.get(`/${userType}`);
            dispatch("auth/setUserData", data[userType], { root: true });
            commit("setUser", data[userType]);
            return data;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "get_user", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "get_user", state: false },
                { root: true }
            );
        }
    },
    async updateUser({ dispatch, rootState }, payload) {
        const { body } = payload;
        let userType = rootState.auth.userType;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "profile", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "profile", state: "" },
                { root: true }
            );

            const { data } = await window.axios.put(`/${userType}`, body);
            // dispatch("auth/setUserData", data.user, { root: true });
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "profile", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "profile", state: false },
                { root: true }
            );
        }
    },
    async updateTenant({ dispatch }, body) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "tenant", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "tenant", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(`/user/tenant`, body);
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "profile", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "profile", state: false },
                { root: true }
            );
        }
    },
    async uploadBrokerLogo({ dispatch }, payload) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "brokerLogoUpload", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "brokerLogoUpload", state: "" },
                { root: true }
            );
            const { body } = payload;
            let formData = new FormData();
            formData.append("photo", body.photo);
            const { data } = await window.axios.post(
                "broker/upload/profile",
                formData
            );
            return data;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "brokerLogoUpload", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "brokerLogoUpload", state: false },
                { root: true }
            );
        }
    },
    async getAddress({ dispatch, rootState }, payload) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "address", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "address", state: "" },
                { root: true }
            );
            const data = await window.axios.get("/postcode", {
                params: payload,
            });

            return data.data;
        } catch (error) {
            let err = "";

            if (error?.response?.data?.errors) {
                let errors = error?.response?.data?.errors;
                console.log("Errors", errors);
                for (let error of Object.keys(errors)) {
                    console.log("Error", error);
                    err += errors[error] + "\n";
                }
            } else if (error?.response?.data?.message) {
                err = error?.response?.data?.message;
            } else {
                err =
                    rootState.locale === "nl"
                        ? "Postcode is ongeldig"
                        : "The given data was invalid";
            }
            dispatch(
                "loadingError/setError",
                { type: "address", state: err },
                { root: true }
            );
            throw new Error(error?.response?.data?.message ?? err);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "address", state: false },
                { root: true }
            );
        }
    },
    async removeTennant({ dispatch }, payload) {
        const { tennantId } = payload;
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "tennantRemoved", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "tennantRemoved", state: "" },
                { root: true }
            );

            const data = await sendRequest({
                method: "DELETE",
                url: `user/tannent/${tennantId}`,
            });
            dispatch("auth/removeTennant", tennantId, { root: true });
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "tennantRemoved", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "tennantRemoved", state: false },
                { root: true }
            );
        }
    },
};

export const userModule = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
