import Vue from "vue";

export function truncateString() {
  return Vue.filter("truncateString", function (value, truncateLength) {
    if (!value) return "";
    if (value.length <= truncateLength) return value;
    value = value.toString();
    return value.substring(0, truncateLength) + "...";
  });
}
