<template>
  <header class="header" :class="{ 'not-login': !isAuth, broker: userType === 'broker' }">
    <!-- if not logged in -->
    <div
      class="header__container d-flex justify-content-between align-items-center h-100"
      v-if="!isAuth"
    >
      <RouterLink to="/"><img class="img-fluid" :src="logo" alt="logo" /></RouterLink>
      <div class="d-flex align-items-center">
        <LanguageDropdown />
        <router-link to="/user/login" class="header__notLoggin__link ml-3">{{
          $t("login")
        }}</router-link>
      </div>
    </div>
    <!-- if logged in -->
    <div
      v-if="isAuth"
      class="header__container d-lg-flex align-items-center d-none h-100"
      :class="{
        broker: userType == 'broker',
        user: userType === 'user',
      }"
    >
      <RouterLink class="align-self-center" to="/">
        <img class="img-fluid" :src="logo" alt="logo" />
      </RouterLink>
      <!-- links for tannent -->
      <div class="d-flex">
        <ul
          class="header__linksContainer justify-content-center d-flex mb-0 pl-0 ml-0"
          :class="{
            user: userType === 'user',
          }"
        >
          <li
            class="d-flex align-items-center position-relative"
            v-for="link in links"
            :key="link.name"
          >
            <a
              v-if="link.external"
              class="header__linksContainer__links px-4 text-center"
              :class="{
                broker: userType == 'broker',
                user: userType === 'user',
              }"
              :href="link.to"
              target="_blank"
            >
              <span class="header__linksContainer__links__link">{{ link.name }}</span>
            </a>
            <router-link
              v-else
              class="header__linksContainer__links pl-0 px-4 text-center"
              :to="link.to"
              :class="{
                broker: userType == 'broker',
                user: userType === 'user',
              }"
            >
              <span class="header__linksContainer__links__link">{{ link.name }}</span>
            </router-link>
            <span
              class="hasMessages"
              v-if="link.label == 'message' && user && user.hasMessages"
            ></span>
          </li>
        </ul>
        <!-- links for broker -->
        <div v-if="userType === 'broker'" class="d-flex align-items-center ml-40px">
          <button
            @click="$router.push({ path: '/broker/addListings' })"
            class="btn btn-primary text-white d-flex align-items-center justify-content-between header__container__addListingBtn"
          >
            <Plus color="#ffff" />
            <span class="pl-3">{{ $t("broker.listingsOverview.filters[3]") }}</span>
          </button>
        </div>

        <div
          v-if="isAuth"
          class="position-relative"
          :class="{
            broker: userType == 'broker',
            user: userType === 'user',
          }"
        >
          <!-- burger menu desktop -->
          <button
            class="p-0 btn d-sm-block d-none header__desktopBurgerBtn"
            @click="showMenuDesktop = !showMenuDesktop"
            :class="{
              broker: userType == 'broker',
              user: userType === 'user',
              'text-primary': userType === 'broker',
            }"
          >
            <BurgerMenuIcon v-if="userType === 'user'" />
            <span class="pr-3" v-if="userType === 'broker'" :title="user.officeName">
              {{
                user.officeName.length > 15
                  ? user.officeName.substring(0, 15) + "..."
                  : user.officeName
              }}
            </span>
            <ChevronDown v-if="userType === 'broker'" />
          </button>

          <button class="p-0 btn d-block d-sm-none" @click="showMenu = !showMenu">
            <BurgerMenuIcon />
          </button>
          <div
            class="position-absolute header__containerDesktop d-sm-block d-none"
            :class="{ show: showMenuDesktop, broker: userType === 'broker' }"
          >
            <div>
              <router-link :to="`/office/${user.slug}`">
                <div class="d-flex">
                  <i class="fa-regular fa-user text-gray fw-bold"></i>
                  <div class="pl-2">
                    <p class="mb-0 text-black">Public profile</p>
                  </div>
                </div>
              </router-link>
              <hr />
            </div>
            <div>
              <router-link :to="profileRoute">
                <div class="d-flex">
                  <i class="fa-regular fa-pen-to-square text-gray fw-bold"></i>
                  <div class="pl-2">
                    <p class="mb-0 text-black">Edit profile</p>
                  </div>
                </div>
              </router-link>
              <hr />
            </div>
            <div>
              <a href="javascript:void()" @click="logout">
                <div class="d-flex">
                  <i class="fa-solid fa-arrow-right-from-bracket text-gray fw-bold"></i>
                  <div class="pl-2">
                    <p class="mb-0 text-black">Logout</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--header  mobile menu -->
    <div
      v-if="isAuth"
      class="header__containerMobile d-flex d-lg-none align-items-center"
      :class="{
        broker: userType == 'broker',
        user: userType === 'user',
      }"
    >
      <img class="img-fluid" :src="logo" alt="" />
      <button class="p-0 btn d-block d-lg-none" @click="showMenu = true">
        <BurgerMenuIcon />
      </button>
      <HeaderMobileMenu
        :links="links"
        :showMenu="showMenu"
        :loginBtnText="loginBtnText"
        @close="showMenu = false"
        :username="username"
      />
    </div>
  </header>
</template>

<script>
import HeaderMobileMenu from "@/components/Header/HeaderMobileMenu.vue";
import BurgerMenuIcon from "@/components/svg/BurgerIcon.vue";

import headerMixin from "@/mixins/headerMixin";
import LanguageDropdown from "./common/LanguageDropdown.vue";

export default {
  mixins: [headerMixin],
  components: {
    HeaderMobileMenu,
    BurgerMenuIcon,
    LanguageDropdown,
  },
  data() {
    return {
      showMenu: false,
      showMenuDesktop: false,

      enFlag: require("../assets/images/common/en.jpg"),
      nlFlag: require("../assets/images/common/nl.png"),
    };
  },
  computed: {
    getFlag() {
      return this.getLocale === "en" ? this.nlFlag : this.enFlag;
    },
    desktopLinks() {
      return [
        {
          to: this.profileRoute,
          name: "userprofile",
          component: "UserIcon",
          text: "My profile",
          type: "link",
          class: "",
        },
        {
          to: null,
          name: "logout",
          component: "questionmark",
          text: this.$t("logout"),
          type: "logoutbtn",
          class: "",
        },
      ];
    },
  },
  methods: {
    closeMenu(e) {
      if (!this.$el.contains(e.target)) {
        this.showMenuDesktop = false;
        this.showMenu = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.closeMenu);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeMenu);
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 80px;
  background: white;

  &__linksContainer {
    &__links {
      &__link {
        @include font(1rem, $dark, 700);
        // @include lg {
        //   font-size: 1rem;
        // }
      }
    }

    &__links.broker {
      padding-right: 7.5rem;
    }

    a {
      &::after {
        @include pseudo;
        height: 3px;
        width: 100%;
        left: 0;
        bottom: -15px;
        background: $primary;
        transform: scaleX(0);
        @include transition(transform 0.3s ease-in-out);
      }

      &:hover,
      &.router-link-active {
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }

  &__linksContainer.user {
    justify-content: flex-end;

    @include lg {
      flex-basis: 40%;
      max-width: 40%;
    }
  }

  &__containerMobile {
    padding: 14px 19px 14px 55px;
    width: 100%;
    justify-content: space-between;

    @include lg {
      padding: 14px 19px;
    }
  }

  &__containerDesktop {
    background: $white;
    width: 272px;
    padding: 20px;

    top: 50px;
    right: 0px;

    border: 1.5px solid #dedede;
    box-shadow: 0px 10px 10px rgba(16, 10, 85, 0.1);
    border-radius: 8px;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    z-index: 0;
    transition: 0.3s all;

    &__link {
      @include font(1rem, #9b9eab, 500);
      vertical-align: center;
    }

    &__link:hover {
      color: #212529;
    }
  }

  &__containerDesktop.broker {
    top: 60px;
  }

  &__desktopBurgerBtn.broker {
    background: #ffffff;
    border: 1.5px solid #e4e4e4;
    border-radius: 8px;

    padding: 12px 20px !important;
    height: 50px;
    margin-left: 30px;
  }

  &__containerDesktop.show {
    z-index: 99;
    opacity: 1;
    user-select: auto;
    pointer-events: auto;
  }

  &__container {
    padding: 0px 55px 0px 55px;
    width: 100%;

    @include lg {
      padding: 14px 19px;
    }

    &__addListingBtn.btn {
      padding: 0.8rem 1.3rem !important;
    }
  }

  &__container.user {
    justify-content: space-between;
  }

  &__container.broker {
    justify-content: space-between;
  }

  &__username {
    @include font(1rem, #9b9eab, 500);
    vertical-align: center;
  }

  &__btn {
    @include lg {
      font-size: 1rem;
    }
  }

  &__languange-btn {
    margin: 0 1rem;
  }
}

.nl {
  .header__linksContainer {
    &.user {
      max-width: unset;
    }
  }
}

.header__notLoggin__link {
  @include font(1rem, #000000, 500);
}
.hasMessages {
  width: 8px;
  height: 8px;
  background: #0a9250;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 5px;
}

i {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
