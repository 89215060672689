import { sendRequest } from "@/utils/sendRequest";

const state = () => ({
    listings: { data: [] },
    listing: null,
    landlords: [],
});

const getters = {
    getListings(state) {
        return state.listings;
    },
    listings(state) {
        return state.listings;
    },
    listing(state) {
        return state.listing;
    },
    getListing(state) {
        return state.listing;
    },
    landlords(state) {
        return state.landlords;
    },
};
const mutations = {
    setListings(state, payload) {
        state.listings = payload;
    },
    setListing(state, payload) {
        state.listing = payload;
    },
    updateListing(state, payload) {
        const updatedListing = state.listings[payload.listingIdx];
        if (updatedListing) {
            const tempUpdatedListing = {
                ...updatedListing,
                ...payload.updates,
            };
            state.listings.splice(payload.listingIdx, 1, tempUpdatedListing);
        }
    },
    deleteListing(state, payload) {
        state.listings.data.splice(payload, 1);
    },
    resetListing(state) {
        state.listing = null;
    },
    setLandlords(state, landlords) {
        state.landlords = landlords;
    },
};

const actions = {
    async addListing({ dispatch }, payload) {
        const { body, mode = "create", urlDynamicPart = "create" } = payload;
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "addUpdateListing", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "addUpdateListing", state: "" },
                { root: true }
            );

            const data = await window.axios.post(
                `broker/listings/${urlDynamicPart}?mode=${mode}`,
                body
            );
            return data;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                {
                    type: "addUpdateListing",
                    state: error?.response?.data?.error ?? error.message,
                },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "addUpdateListing", state: false },
                { root: true }
            );
        }
    },
    async updateListing({ dispatch }, { listingId, payload }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "update_listing", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "update_listing", state: "" },
                { root: true }
            );

            const data = await window.axios.post(
                `broker/listings/${listingId}`,
                payload
            );
            return data;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                {
                    type: "update_listing",
                    state: error?.response?.data?.error ?? error.message,
                },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "update_listing", state: false },
                { root: true }
            );
        }
    },
    async disableViewingRequest({ dispatch }, listingId) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "update_listing", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "update_listing", state: "" },
                { root: true }
            );

            const data = await window.axios.post(
                `broker/listings/disable-viewing-request`,
                { listing_id: listingId }
            );

            dispatch(
                "loadingError/setSuccess",
                {
                    type: "update_listing",
                    message: "Viewing request disabled successfully",
                },
                { root: true }
            );

            return data;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                {
                    type: "update_listing",
                    state: error?.response?.data?.error ?? error.message,
                },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "update_listing", state: false },
                { root: true }
            );
        }
    },
    async uploadMedia({ dispatch }, payload) {
        const { body } = payload;
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "listingMedia", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "listingMedia", state: "" },
                { root: true }
            );

            // const data = await sendRequest({
            //   method: "POST",
            //   url: `media/listing`,
            //   body,
            //   options: {},
            //   multipart: true,
            // });
            let formData = new FormData();
            formData.append("photo", body.photo);
            const { data } = await window.axios.post(
                "broker/listings/upload/media",
                formData
            );
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "listingMedia", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "listingMedia", state: false },
                { root: true }
            );
        }
    },
    async setListings({ dispatch, commit }, payload = {}) {
        commit("setListings", { data: [] });
        let { url = `/listings?` } = payload;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "listings", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "listings", state: "" },
                { root: true }
            );
            const { data } = await window.axios.get(url);
            commit("setListings", data.listings);
            // return data;
        } catch (error) {
            console.log(error);
            dispatch(
                "loadingError/setError",
                { type: "listings", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "listings", state: false },
                { root: true }
            );
        }
    },
    async cloneListing({ dispatch }, { listing_id }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "clone_listing", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "clone_listing", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(
                "/broker/listings/duplicate",
                { listing_id }
            );
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "clone_listing", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "clone_listing", state: false },
                { root: true }
            );
        }
    },
    async setListing({ dispatch, commit }, payload) {
        commit("setListing", null);
        const { listingId, query } = payload;
        let url = `listings/${listingId}`;
        if (query) {
            url += `?${query}`;
        }
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "set_listing", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "set_listing", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(url);

            commit("setListing", data.listing);
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "set_listing", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "set_listing", state: false },
                { root: true }
            );
        }
    },
    async getBrokerListing({ dispatch, commit }, listingId) {
        commit("setListing", null);
        let url = `broker/listings/${listingId}`;
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "set_listing", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "set_listing", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(url);

            commit("setListing", data.listing);
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "set_listing", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "set_listing", state: false },
                { root: true }
            );
        }
    },
    async setSchedule({ dispatch }, payload) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "schedule", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "schedule", state: "" },
                { root: true }
            );

            const data = await window.axios.post(`/user/schedule`, payload);
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "schedule", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "schedule", state: false },
                { root: true }
            );
        }
    },
    async setUserPreferenceListings({ dispatch, commit }, { query }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "listings", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "listings", state: "" },
                { root: true }
            );

            console.log("query", query);
            const { data } = await window.axios.get(`user/listings`, {
                params: query,
            });

            let listings = data.listings;
            if (data.savedListings && data.savedListings.length) {
                listings = data.listings.map((listing) => {
                    const savedListingIdx = data.savedListings.findIndex(
                        (li) => li.ListingId === listing.id
                    );
                    return {
                        ...listing,
                        reacted: savedListingIdx > -1,
                    };
                });
            }
            commit("setListings", listings);
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "listings", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "listings", state: false },
                { root: true }
            );
        }
    },
    async changeSavedListingStatus({ dispatch, commit }, payload) {
        let { url, status, listingIdx } = payload;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "listingStatus", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "llistingStatus", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(url);

            commit("updateListing", {
                updates: { savedListingStatus: status },
                listingIdx,
            });
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "listingStatus", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "listingStatus", state: false },
                { root: true }
            );
        }
    },
    async deleteListing({ dispatch, commit }, payload) {
        const { listingId, index } = payload;

        let url = `/broker/listings/destroy/${listingId}`;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "delete_listing", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "delete_listing", state: "" },
                { root: true }
            );

            const { data } = await window.axios.delete(url);
            commit("deleteListing", index);
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "delete_listing", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "delete_listing", state: false },
                { root: true }
            );
        }
    },
    async deleteListingMedia({ dispatch }, payload) {
        const { listingId, id } = payload;

        let url = `/broker/listings/${listingId}/media/${id}`;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "listingMedia", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "listingMedia", state: "" },
                { root: true }
            );

            const { data } = window.axios.delete(url);
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "listingMedia", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "listingMedia", state: false },
                { root: true }
            );
        }
    },
    async swapMediaOrder({ dispatch }, payload) {
        const { listingId, orderTo, orderFrom, type } = payload;

        let url = `listings/media/rearrange/${listingId}`;

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "listingMedia", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "listingMedia", state: "" },
                { root: true }
            );

            const data = await sendRequest({
                method: "PUT",
                url,
                body: { orderTo, orderFrom, type },
            });
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "listingMedia", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "listingMedia", state: false },
                { root: true }
            );
        }
    },
    async getLandlords({ commit }) {
        try {
            const { data } = await window.axios.get("/broker/landlords/list");
            commit("setLandlords", data.landlords);
        } catch (error) {
            let errorMessage = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            console.log("errorMessage", errorMessage);
        }
    },
    resetListing({ commit }) {
        commit("resetListing");
    },
};

export const listingModule = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
