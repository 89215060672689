const actions = {
    async reportCandidate({ dispatch }, payload) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "reportCandidate", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "reportCandidate", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(`/broker/report/user`, payload);
            return data;
        } catch (error) {
            console.log(error.message);
            dispatch(
                "loadingError/setError",
                { type: "reportCandidate", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "reportCandidate", state: false },
                { root: true }
            );
        }
    },
};

export const reportCandidateModule = {
    actions,
    namespaced: true,
};
