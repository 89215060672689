import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload";
import Spinner from "./assets/spinner.gif";
import { BootstrapVue } from "bootstrap-vue";
import { ValidationProvider, setInteractionMode, localize } from "vee-validate";
import vClickOutside from "v-click-outside";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import VueDOMPurifyHTML from "vue-dompurify-html";

import "leaflet/dist/leaflet.css";
import "./scss/main.scss";

import { Icon } from "leaflet";

import nl from "vee-validate/dist/locale/nl.json";
import en from "vee-validate/dist/locale/en.json";

import i18n from "./utils/i18n";

import { truncateString } from "./filters/truncateString";
import { formatCurrency } from "./filters/currencyFormatter";
//  Filters
truncateString();
formatCurrency();
// let user = localStorage.getItem('user');
// user = JSON.parse(user);
let accessToken = localStorage.getItem("accessToken");

window.axios = require("axios");
window.axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
window.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
window.axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response?.status === 401) {
            store.dispatch("auth/logout");
        }
        if (error.response?.status === 404) {
            // store.commit("set404", true);
            router.push("/404");
        }
        return Promise.reject(error);
    }
);

// Vee validate

localize({
    en: {
        messages: {
            ...en.messages,
            required: "This field is required",
            numeric: "This field must be a number",
            image: "Only images are allowed",
            max: "This field is too long",
            min: "This field is too short",
            confirmed: "Passwords don't match",
            isValidPhoneNumber: "Phone number is invalid",
            minArrayLength: (_, { length }) =>
                `Minimum ${length} items are required`,
            maxArrayLength: (_, { length }) =>
                `Maximum ${length} items are allowed`,
            maxValue: (_, { max }) => `Maximum allowed Value is ${max}`,
        },
    },
    nl: {
        messages: {
            ...nl.messages,
            required: "Dit veld is verplicht",
            numeric: "Het veld moet een getal zijn",
            images: "Aalleen afbeeldingen zijn toegestaan",
            max: "Dit veld is te lang",
            min: "Dit veld is te kort",
            confirmed: "Wachtwoorden komen niet overeen",
            isValidPhoneNumber: "Telefoonnummer is ongeldig",
            minArrayLength: (_, { length }) =>
                `Minimaal ${length} items zijn vereist`,
            maxArrayLength: (_, { length }) =>
                `Maximaal ${length} items zijn toegestaan`,
            maxValue: (_, { max }) => `Maximum allowed Value is ${max}`,
        },
    },
});

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.component("ValidationProvider", ValidationProvider);
setInteractionMode("eager");
import rules from "./validation/rules";
rules();
Vue.use(require("vue-moment"));

// Vue lazyload
Vue.use(VueLazyload, {
    preLoad: 1.3,
    // error: 'dist/error.png',
    loading: Spinner,
    // attempt: 1
});

// Bootstrap vue
Vue.use(BootstrapVue);

// V-click outside
Vue.use(vClickOutside);

// Dom purify
Vue.use(VueDOMPurifyHTML);

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
