<template>
  <div>
    <div class="w-100 landlordHeader d-flex align-items-center">
      <RouterLink to="/" class="align-self-center">
        <img
          class="img-fluid"
          :src="require('@/assets/images/common/logo-ETH.svg')"
          alt="logo"
        />
      </RouterLink>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
.landlordHeader {
  padding: 0px 52px;
  background: white;
  border-bottom: 2px solid $gray2;
  height: 80px;
  padding: 0px 55px 0px 55px;

  @include xs {
    flex-direction: column-reverse;
    padding: 32px 15px;
    height: auto;
  }

  &__text {
    color: #7f8494 !important;
    font-size: 0.875rem;
    font-weight: 700;
  }

  &__text::hover {
    color: #7f8494 !important;
  }

  &__logo {
    @include sm {
      left: auto;
      right: 52px;
      transform: translate(0%, -50%);
    }

    @include xs {
      position: static;
      transform: translate(0, 0);
      padding-bottom: 15px;
    }
  }
}

.container {
  height: calc(100vh - 80px);
}
</style>
