import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

var globalMessages = { en: {}, nl: {} };

async function loadLocaleMessages() {
    const locales = require.context(
        "../locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        const locale = matched[1];
        globalMessages[locale] = locales(key);
    });

    let response = await fetch(
        "https://gementee-rotterdam-bucket.s3.eu-central-1.amazonaws.com/locales/en.json"
    );
    response = await response.json();
    globalMessages.en = { ...globalMessages.en, ...response };

    response = await fetch(
        "https://gementee-rotterdam-bucket.s3.eu-central-1.amazonaws.com/locales/nl.json"
    );
    response = await response.json();
    globalMessages.nl = { ...globalMessages.nl, ...response };
}

loadLocaleMessages();
// const defaultLocale = store.getters["auth/getUserType"] == "user" ? "en" : "nl";
export default new VueI18n({
    // locale: store.state.locale,
    locale: "en",
    fallbackLocale: "en",
    messages: globalMessages,
    silentTranslationWarn: true,
});
