import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { authModule } from "./modules/auth";
import { loadingErrorModule } from "./modules/loadingError";
import { userModule } from "./modules/user";
import { listingModule } from "./modules/listing";
import { requestsAndAppointmentModule } from "./modules/requestsAndAppointment";
import { reportCandidateModule } from "./modules/report";
import { qiiModule } from "./modules/qii";
import { paymentModule } from "./modules/payment";
import { calendar } from "./modules/calendar";
import { messages } from "./modules/messages";
import { nordigen } from "./modules/nordigen";
import { fileModule } from "./modules/file";

import VuexPersistence from "vuex-persist";
import { tenantModule } from "./modules/tenant";

const vuexLocal = new VuexPersistence({
    key: "gementee-rotterdam-store",
    storage: window.localStorage,
    reducer: (state) => ({
        auth: {
            token: state.auth.token,
            user: state.auth.user,
            userType: state.auth.userType,
        },
        locale: state.locale,
        numberOfTennants: state.numberOfTennants,
    }),
});

export default new Vuex.Store({
    state: { locale: "nl", numberOfTennants: null },
    getters: {
        getLocale(state) {
            return state.locale;
        },
        locale(state) {
            return state.locale;
        },
        _404(state) {
            return state._404;
        },
    },
    mutations: {
        setLocale(state, payload) {
            state.locale = payload;
        },
        setNumberOfTennants(state, payload) {
            state.numberOfTennants = payload;
        },
    },
    actions: {
        setLocale({ commit }, payload) {
            commit("setLocale", payload);
        },
        setNumberOfTennants({ commit }, payload) {
            commit("setNumberOfTennants", payload);
        },
    },
    modules: {
        auth: authModule,
        loadingError: loadingErrorModule,
        user: userModule,
        listing: listingModule,
        requestsAndAppointment: requestsAndAppointmentModule,
        report: reportCandidateModule,
        qii: qiiModule,
        payment: paymentModule,
        calendar: calendar,
        messages: messages,
        nordigen: nordigen,
        file: fileModule,
        tenant: tenantModule,
    },
    plugins: [vuexLocal.plugin],
});
