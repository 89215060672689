<template>
  <div>
    <UserHeader v-if="userType == 'user'" />
    <AppHeader
      v-else
      :links="brokerLinks"
      :loginBtnText="
        routeType == 'user' || routeType === 'general' ? $t('header.login') : $t('login')
      "
      :username="user ? user.name : 'anonymous'"
      :officeName="user ? user.officeName : 'anonymous'"
      :avatar="user && user.logo"
    />
    <slot></slot>
  </div>
</template>

<script>
import AppHeader from "@/components/Header.vue";
import { mapGetters } from "vuex";
import headerMixin from "@/mixins/headerMixin";
import UserHeader from "./UserHeader.vue";

export default {
  name: "MainLayout",
  mixins: [headerMixin],
  components: {
    AppHeader,
    UserHeader,
  },

  data() {
    return {
      brokername: "Verra Real Estate",
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isAuth", "userType"]),
    // setting header links based on whether current route is for user or broker
    userLinks() {
      const localePart = this.$i18n.locale === "en" ? "en/" : "";
      if (this.isAuth) {
        return [
          {
            to: "/listings?page=1",
            name: this.$t("listings"),
          },
          {
            to: "/listings/saved",
            name: this.$t("savedListings"),
          },
          {
            to: this.profileRoute,
            name: this.$t("myProfile"),
          },
          {
            to: "/messages",
            name: this.$t("messages"),
            label: "message",
          },
        ];
      }
      return [
        {
          to: "/listings?page=1",
          name: this.$t("listings"),
        },
        {
          to: `https://info.eerlijktehuur.nl/${localePart}faq`,
          name: this.$t("header.faq"),
          external: true,
        },
        {
          to: `https://info.eerlijktehuur.nl/${localePart}over-ons`,
          name: this.$t("header.about"),
          external: true,
        },
      ];
    },
    brokerLinks() {
      return [
        {
          to: "/broker/listings",
          name: this.$t("header.myListing"),
        },
        {
          to: "/broker/calendar",
          name: this.$t("header.calendar"),
        },
        {
          to: "/messages",
          name: this.$t("messages"),
          label: "message",
        },
        {
          to: "/broker/landlords",
          name: this.$t("contacts"),
          label: "contacts",
        },
      ];
    },
    routeType() {
      return this.$route.meta.type;
    },
  },
  mounted() {
    if (this.userType == "broker") {
      this.loadHelpscout();
    }
  },
  methods: {
    loadHelpscout() {
      !(function (e, t, n) {
        function a() {
          var e = t.getElementsByTagName("script")[0],
            n = t.createElement("script");
          (n.type = "text/javascript"),
            (n.async = !0),
            (n.src = "https://beacon-v2.helpscout.net"),
            e.parentNode.insertBefore(n, e);
        }
        if (
          ((e.Beacon = n = function (t, n, a) {
            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
          }),
          (n.readyQueue = []),
          "complete" === t.readyState)
        )
          return a();
        e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1);
      })(window, document, window.Beacon || function () {});

      window.Beacon("init", "8a4db305-45dd-462b-b972-90a4a7c337e0");
    },
  },
};
</script>

<style lang="scss" scoped></style>
