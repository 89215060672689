import Vue from "vue";

export function formatCurrency() {
  return Vue.filter("formatCurrency", function (value) {
    if (isNaN(+value)) return 0;
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  });
}
