<template>
  <div class="">
    <router-link to="/">
      <img class="pl-4 pt-3" :src="logo" alt="logo" />
    </router-link>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../assets/images/common/logo-ETH.svg"),
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
