<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0419 7.68007L11.8763 7.67941L11.876 1.95812C11.8763 0.876682 10.9993 0 9.91783 0C8.83672 0 7.96004 0.876682 7.96004 1.95812V7.67974L1.95812 7.67941C0.876681 7.67974 0 8.55643 0 9.63753C0.000662647 10.719 0.876681 11.5953 1.95812 11.5953H7.96004V18.0419C7.96004 19.1243 8.83672 20 9.91816 20C10.9996 20 11.876 19.1233 11.8763 18.0425V11.5953H18.0425C19.1233 11.5953 20 10.719 20 9.6382C20.001 8.55609 19.1233 7.67941 18.0419 7.68007Z"
      :fill="color || '#0A9250'"
    />
  </svg>
</template>

<script>
export default { props: ["color"] };
</script>

<style lang="scss" scoped></style>
