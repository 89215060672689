<template>
  <b-dropdown variant="white" size="sm" class="languageDropdown">
    <template #button-content>
      <img
        class="mr-2"
        width="19"
        height="19"
        :src="`https://cdn.weglot.com/flags/circle/${
          currentLang == 'en' ? 'gb' : currentLang
        }.svg`"
        alt="flag"
      />
      <span class="mr-1">
        {{ currentLang.toUpperCase() }}
      </span>
    </template>
    <b-dropdown-item v-if="currentLang != 'en'" @click="switchLanguage('en')">
      <img
        class="mr-2"
        width="19"
        height="19"
        src="https://cdn.weglot.com/flags/circle/gb.svg"
        alt="flag"
      />
      English
    </b-dropdown-item>
    <b-dropdown-item v-if="currentLang != 'nl'" @click="switchLanguage('nl')">
      <img
        class="mr-2"
        width="19"
        height="19"
        src="https://cdn.weglot.com/flags/circle/nl.svg"
        alt="flag"
      />
      Dutch
    </b-dropdown-item>
    <b-dropdown-item v-if="currentLang != 'tr'" @click="switchLanguage('tr')">
      <img
        class="mr-2"
        width="19"
        height="19"
        src="https://cdn.weglot.com/flags/circle/tr.svg"
        alt="flag"
      />
      Turkish
    </b-dropdown-item>
    <b-dropdown-item v-if="currentLang != 'fr'" @click="switchLanguage('fr')">
      <img
        class="mr-2"
        width="19"
        height="19"
        src="https://cdn.weglot.com/flags/circle/fr.svg"
        alt="flag"
      />
      French
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["userType", "user", "locale"]),
    currentLang() {
      return this.locale;
    },
  },
  mounted() {},
  methods: {
    ...mapActions("auth", ["switchLocale"]),
    switchLanguage(language) {
      this.switchLocale(language);
      if (this.user) {
        window.axios.get(`/${this.userType}/set-locale/${language}`);
      }
    },
  },
};
</script>
