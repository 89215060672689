<template>
  <div id="app">
    <component :is="$route.meta.layout || 'div'">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["getLocale"]),
    ...mapGetters("auth", ["userType", "user", "isAuth"]),
  },
  created() {
    // if (this.getLocale() === "nl") document.body.classList.add("nl");
    // localize(this.getLocale() || "nl");
    setTimeout(() => {
      let currentLang = window.Weglot?.getCurrentLang() || "en";
      if (this.isAuth) {
        if (this.user?.locale) {
          console.log("this.user.locale", this.user.locale, currentLang);
          this.setLocale(this.user.locale);
          if (this.user.locale != currentLang) {
            setTimeout(() => {
              this.switchLocale(this.user.locale);
            }, 1000);
          }
        }
      } else {
        if (
          ["BrokerLogin", "BrokerRegister", "UserLogin", "UserRegister"].includes(
            this.$route.name
          )
        ) {
          this.switchLocale("nl");
        }
      }
    }, 1000);
  },
  methods: {
    ...mapActions("auth", ["switchLocale"]),
    ...mapMutations("auth", ["setLocale"]),
  },
};
</script>

<style lang="scss"></style>
