var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header",class:{ 'not-login': !_vm.isAuth, broker: _vm.userType === 'broker' }},[(!_vm.isAuth)?_c('div',{staticClass:"header__container d-flex justify-content-between align-items-center h-100"},[_c('RouterLink',{attrs:{"to":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('div',{staticClass:"d-flex align-items-center"},[_c('LanguageDropdown'),_c('router-link',{staticClass:"header__notLoggin__link ml-3",attrs:{"to":"/user/login"}},[_vm._v(_vm._s(_vm.$t("login")))])],1)],1):_vm._e(),(_vm.isAuth)?_c('div',{staticClass:"header__container d-lg-flex align-items-center d-none h-100",class:{
      broker: _vm.userType == 'broker',
      user: _vm.userType === 'user',
    }},[_c('RouterLink',{staticClass:"align-self-center",attrs:{"to":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('div',{staticClass:"d-flex"},[_c('ul',{staticClass:"header__linksContainer justify-content-center d-flex mb-0 pl-0 ml-0",class:{
          user: _vm.userType === 'user',
        }},_vm._l((_vm.links),function(link){return _c('li',{key:link.name,staticClass:"d-flex align-items-center position-relative"},[(link.external)?_c('a',{staticClass:"header__linksContainer__links px-4 text-center",class:{
              broker: _vm.userType == 'broker',
              user: _vm.userType === 'user',
            },attrs:{"href":link.to,"target":"_blank"}},[_c('span',{staticClass:"header__linksContainer__links__link"},[_vm._v(_vm._s(link.name))])]):_c('router-link',{staticClass:"header__linksContainer__links pl-0 px-4 text-center",class:{
              broker: _vm.userType == 'broker',
              user: _vm.userType === 'user',
            },attrs:{"to":link.to}},[_c('span',{staticClass:"header__linksContainer__links__link"},[_vm._v(_vm._s(link.name))])]),(link.label == 'message' && _vm.user && _vm.user.hasMessages)?_c('span',{staticClass:"hasMessages"}):_vm._e()],1)}),0),(_vm.userType === 'broker')?_c('div',{staticClass:"d-flex align-items-center ml-40px"},[_c('button',{staticClass:"btn btn-primary text-white d-flex align-items-center justify-content-between header__container__addListingBtn",on:{"click":function($event){return _vm.$router.push({ path: '/broker/addListings' })}}},[_c('Plus',{attrs:{"color":"#ffff"}}),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.$t("broker.listingsOverview.filters[3]")))])],1)]):_vm._e(),(_vm.isAuth)?_c('div',{staticClass:"position-relative",class:{
          broker: _vm.userType == 'broker',
          user: _vm.userType === 'user',
        }},[_c('button',{staticClass:"p-0 btn d-sm-block d-none header__desktopBurgerBtn",class:{
            broker: _vm.userType == 'broker',
            user: _vm.userType === 'user',
            'text-primary': _vm.userType === 'broker',
          },on:{"click":function($event){_vm.showMenuDesktop = !_vm.showMenuDesktop}}},[(_vm.userType === 'user')?_c('BurgerMenuIcon'):_vm._e(),(_vm.userType === 'broker')?_c('span',{staticClass:"pr-3",attrs:{"title":_vm.user.officeName}},[_vm._v(" "+_vm._s(_vm.user.officeName.length > 15 ? _vm.user.officeName.substring(0, 15) + "..." : _vm.user.officeName)+" ")]):_vm._e(),(_vm.userType === 'broker')?_c('ChevronDown'):_vm._e()],1),_c('button',{staticClass:"p-0 btn d-block d-sm-none",on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},[_c('BurgerMenuIcon')],1),_c('div',{staticClass:"position-absolute header__containerDesktop d-sm-block d-none",class:{ show: _vm.showMenuDesktop, broker: _vm.userType === 'broker' }},[_c('div',[_c('router-link',{attrs:{"to":`/office/${_vm.user.slug}`}},[_c('div',{staticClass:"d-flex"},[_c('i',{staticClass:"fa-regular fa-user text-gray fw-bold"}),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"mb-0 text-black"},[_vm._v("Public profile")])])])]),_c('hr')],1),_c('div',[_c('router-link',{attrs:{"to":_vm.profileRoute}},[_c('div',{staticClass:"d-flex"},[_c('i',{staticClass:"fa-regular fa-pen-to-square text-gray fw-bold"}),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"mb-0 text-black"},[_vm._v("Edit profile")])])])]),_c('hr')],1),_c('div',[_c('a',{attrs:{"href":"javascript:void()"},on:{"click":_vm.logout}},[_vm._m(0)])])])]):_vm._e()])],1):_vm._e(),(_vm.isAuth)?_c('div',{staticClass:"header__containerMobile d-flex d-lg-none align-items-center",class:{
      broker: _vm.userType == 'broker',
      user: _vm.userType === 'user',
    }},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.logo,"alt":""}}),_c('button',{staticClass:"p-0 btn d-block d-lg-none",on:{"click":function($event){_vm.showMenu = true}}},[_c('BurgerMenuIcon')],1),_c('HeaderMobileMenu',{attrs:{"links":_vm.links,"showMenu":_vm.showMenu,"loginBtnText":_vm.loginBtnText,"username":_vm.username},on:{"close":function($event){_vm.showMenu = false}}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('i',{staticClass:"fa-solid fa-arrow-right-from-bracket text-gray fw-bold"}),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"mb-0 text-black"},[_vm._v("Logout")])])])
}]

export { render, staticRenderFns }