const state = () => ({});

const getters = {};
const mutations = {};

const actions = {
    async uploadFile({ dispatch, rootState }, { type, file }) {
        let userType = rootState.auth.userType;
        let formData = new FormData();
        formData.append("type", type);
        formData.append("file", file);

        try {
            dispatch(
                "loadingError/setLoading",
                { type: "file_upload", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "file_upload", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(
                `/${userType}/file/upload`,
                formData
            );
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "file_upload", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "file_upload", state: false },
                { root: true }
            );
        }
    },
    async getFiles({ dispatch, rootState }) {
        let userType = rootState.auth.userType;
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "getting_files", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "getting_files", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(`/${userType}/file/list`);
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "getting_files", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "getting_files", state: false },
                { root: true }
            );
        }
    },
};

export const fileModule = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
