const state = () => ({
    errors: {
        auth: "",
        profile: "",
        address: "",
        listingMedia: "",
        addUpdateListing: "",
        listings: "",
        listing: "",
        set_listing: "",
        schedule: "",
        viewingRequests: "",
        invitation: "",
        invitation_cancel: "",
        reportCandidate: "",
        assigning: "",
        verification: "",
        listingStatus: "",
        tennantRemoved: "",
        cancelAssigning: "",
        logout: "",
        resetPass: "",
        validateAssigning: "",
        qii: "",
        loading: "",
        payment: "",
        brokerLogoUpload: "",
        nordigen_accounts: "",
        nordigen_set_account: "",
        nordigen_set_transaction: "",
        verify_otp: "",
        sending_otp: "",
        get_user: "",
        file_upload: "",
        getting_files: "",
        get_tenant: "",
        delete_tenant: "",
        tenant: "",
        nordigen_transactions: "",
        delete_listing: "",
        share_rent_log: "",
        rent_log: "",
        calendar: "",
        update_listing: "",
        appointment_export_overview: "",
    },
    loading: {
        auth: false,
        profile: false,
        address: false,
        listingMedia: false,
        addUpdateListing: false,
        listings: false,
        listing: false,
        set_listing: false,
        schedule: false,
        viewingRequests: false,
        invitation: false,
        invitation_cancel: false,
        reportCandidate: false,
        assigning: false,
        verification: false,
        listingStatus: false,
        tennantRemoved: false,
        cancelAssigning: false,
        logout: false,
        resetPass: false,
        validateAssigning: false,
        qii: false,
        loading: false,
        payment: false,
        brokerLogoUpload: false,
        messages: false,
        conversation: false,
        send_message: false,
        nordigen_accounts: false,
        nordigen_set_account: false,
        nordigen_set_transaction: false,
        verify_otp: false,
        sending_otp: false,
        get_user: false,
        file_upload: false,
        getting_files: false,
        get_tenant: false,
        delete_tenant: false,
        tenant: false,
        nordigen_transactions: false,
        delete_listing: false,
        share_rent_log: false,
        rent_log: false,
        calendar: false,
        update_listing: false,
        appointment_export_overview: false,
    },
    success: {
        update_listing: "",
    },
});

const getters = {
    getErrors(state) {
        return state.errors;
    },
    getLoadings(state) {
        return state.loading;
    },
    getSuccess(state) {
        return state.success;
    },
};
const mutations = {
    setError(state, payload) {
        state.errors[payload.type] = payload.state;
    },
    setLoading(state, payload) {
        state.loading[payload.type] = payload.state;
    },
    setSuccess(state, payload) {
        state.success[payload.type] = payload.message;
    },
};

const actions = {
    async setError({ commit }, payload) {
        commit("setError", payload);
    },
    async setLoading({ commit }, payload) {
        commit("setLoading", payload);
    },
    async setSuccess({ commit }, payload) {
        commit("setSuccess", payload);
    },
};

export const loadingErrorModule = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
