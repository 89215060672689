import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import MainLayout from "@/layouts/MainLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import SimpleLogoLayout from "../layouts/SimpleLogoLayout.vue";
import LandlordLayout from "@/layouts/LandlordLayout.vue";
import store from "@/store/index";
// import BrokerLayout from "@/layouts/BrokerLayout.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import UserRoutes from "./user.js";
import BrokerRoutes from "./broker.js";

let routes = [
    // general routes
    {
        path: "/",
        redirect: () => {
            let user_type = store.state.auth.userType;
            if (user_type == "broker") {
                return "/broker/listings";
            }
            if (user_type == "admin") {
                return "/admin";
            }

            return "/listings";
        },
    },
    {
        path: "/registrationcompleted",
        name: "Registrationcompleted",
        component: () => import("../views/RegistrationCompleted.vue"),

        meta: {
            layout: SimpleLogoLayout,
            type: "general",
        },
    },

    {
        path: "/listings/map",
        name: "ListingsMap",
        component: () => import("../views/User/Listings/GMap.vue"),
        meta: {
            layout: MainLayout,
            type: "general",
        },
    },

    {
        path: "/verification",
        name: "Verification",
        component: () => import("../views/Verification.vue"),
        meta: {
            layout: SimpleLogoLayout,
            type: "general",
        },
    },
    {
        path: "/lostPassword/email",
        name: "LostPasswordEmailForm",
        component: () => import("../views/LostPasswordMail.vue"),
        meta: {
            layout: SimpleLogoLayout,
            type: "general",
        },
    },
    {
        path: "/lostPassword/message",
        name: "LostPasswordMessage",
        component: () => import("../views/LostPasswordMessage.vue"),
        meta: {
            layout: SimpleLogoLayout,
            type: "general",
        },
    },
    {
        path: "/password/reset/:type/:token",
        name: "LostPasswordReset",
        component: () => import("../views/LostPasswordReset.vue"),
        meta: {
            layout: SimpleLogoLayout,
            type: "general",
        },
    },

    {
        path: "/aboutQii",
        name: "AboutQii",
        component: () => import("../views/AboutQii.vue"),
        meta: {
            layout: MainLayout,
            type: "general",
        },
    },

    // user routes

    // {
    //   path: "/registrationcompleted",
    //   name: "Registrationcompleted",
    //   component: () =>
    //     import(
    //        "../views/RegistrationCompleted.vue"
    //     ),

    //   meta: {
    //     layout: MainLayout,
    //     type:process.env.VUE_APP_USER,
    //   },
    // },

    {
        path: "/listings/:listingId",
        name: "Listing",
        component: () => import("../views/Listing.vue"),
        meta: {
            layout: MainLayout,
            type: "general",
        },
    },

    {
        path: "/user/nordigen",
        name: "NordigenSteps",
        component: () => import("../views/User/nordigen/Steps.vue"),
        meta: {
            layout: AuthLayout,
            type: process.env.VUE_APP_USER,
            protected: true,
        },
        children: [
            {
                path: "1",
                name: "NordigenStep1",
                component: () => import("../views/User/nordigen/Step1.vue"),
                meta: {
                    layout: AuthLayout,
                    type: process.env.VUE_APP_USER,
                    protected: true,
                },
            },
            {
                path: "2",
                name: "NordigenStep2",
                component: () => import("../views/User/nordigen/Step2.vue"),
                meta: {
                    layout: AuthLayout,
                    type: process.env.VUE_APP_USER,
                    protected: true,
                },
            },
            // {
            //   path: "3",
            //   name: "NordigenStep3",
            //   component: () => import("../views/User/nordigen/Step3.vue"),
            //   meta: {
            //     layout: AuthLayout,
            //     type: process.env.VUE_APP_USER,
            //     protected: true,
            //   },
            // },
            // {
            //   path: "3/:tenant",
            //   name: "NordigenStep3Tenant",
            //   component: () => import("../views/User/nordigen/Step3.vue"),
            //   meta: {
            //     layout: AuthLayout,
            //     type: process.env.VUE_APP_USER,
            //     protected: true,
            //   },
            // },
            // {
            //   path: "4",
            //   name: "NordigenStep4",
            //   component: () => import("../views/User/nordigen/Step4.vue"),
            //   meta: {
            //     layout: AuthLayout,
            //     type: process.env.VUE_APP_USER,
            //     protected: true,
            //   },
            // },
            // {
            //   path: "4/:tenant",
            //   name: "NordigenStep4Tenant",
            //   component: () => import("../views/User/nordigen/Step4.vue"),
            //   meta: {
            //     layout: AuthLayout,
            //     type: process.env.VUE_APP_USER,
            //     protected: true,
            //   },
            // },
        ],
    },
    // broker routes

    {
        path: "/messages",
        name: "Messages",
        component: () => import("../views/Messages/Index.vue"),
        meta: {
            layout: MainLayout,
            type: "general",
            protected: true,
        },
    },
    {
        path: "/broker/landlords",
        name: "BrokerLandlords",
        component: () => import("../views/Broker/Landlord/Index.vue"),
        meta: {
            layout: MainLayout,
            type: "general",
        },
    },
    {
        path: "/landlord/listing/:listing_id/auth",
        name: "LandlordListingAuth",
        component: () => import("../views/Landlord/Listing/Auth.vue"),
        meta: {
            layout: LandlordLayout,
            type: "general",
        },
    },
    {
        path: "/landlord/listing/:listing_id/assignings",
        name: "LandlordListingAssignings",
        component: () => import("../views/Landlord/Listing/Assignings.vue"),
        meta: {
            layout: LandlordLayout,
            type: "general",
        },
    },
    {
        path: "/admin",
        name: "Admin",
        component: () => import("../views/Admin/Index.vue"),
        meta: {
            layout: AdminLayout,
            protected: true,
            type: "admin",
        },
        children: [
            {
                path: "",
                name: "Dashboard",
                component: () => import("../views/Admin/Dashboard.vue"),
                meta: {
                    layout: AdminLayout,
                    protected: true,
                    type: "admin",
                },
            },
            {
                path: "login",
                name: "AdminLogin",
                component: () => import("../views/Admin/Login.vue"),
            },
            {
                path: "languages",
                name: "AdminLanguages",
                component: () => import("../views/Admin/Languages.vue"),
                meta: {
                    layout: AdminLayout,
                    protected: true,
                    type: "admin",
                },
            },
            {
                path: "languages/:key",
                name: "LanguageValues",
                component: () => import("../views/Admin/LanguageValues.vue"),
                meta: {
                    layout: AdminLayout,
                    protected: true,
                    type: "admin",
                },
            },
            {
                path: "users",
                name: "AdminUsers",
                component: () => import("../views/Admin/Users.vue"),
                meta: {
                    layout: AdminLayout,
                    protected: true,
                    type: "admin",
                },
            },
            {
                path: "user/:id",
                name: "AdminUserDetail",
                component: () => import("../views/Admin/UserDetail.vue"),
                meta: {
                    layout: AdminLayout,
                    protected: true,
                    type: "admin",
                },
            },
        ],
    },
    {
        path: "/user/redirect",
        name: "RedirectUser",
        component: () => import("../views/User/Redirect.vue"),
        meta: {
            layout: SimpleLogoLayout,
            type: process.env.VUE_APP_USER,
            protected: true,
        },
    },
    {
        path: "/tenant/:tenant_id",
        name: "TenantDetail",
        component: () => import("../views/Tenant/Detail.vue"),
        meta: {
            layout: MainLayout,
            type: "general",
            protected: true,
        },
    },
    {
        path: "/404",
        name: "404",
        component: () => import("../views/404.vue"),
        meta: {
            layout: MainLayout,
            type: "general",
            protected: true,
        },
    },
    {
        path: "/503",
        name: "503",
        component: () => import("../views/503.vue"),
        meta: {
            type: "general",
            protected: true,
        },
    },
    {
        path: "/imp",
        name: "imp",
        component: () => import("../views/Impersonate.vue"),
        meta: {
            type: "general",
        },
    },
];

routes = [...UserRoutes, ...BrokerRoutes, ...routes];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    // if (to.name != "503") {
    //     return next(`503`);
    // }
    if (process.env.VUE_APP_ENV != "production") {
        const pass = 18051989;
        let enteredPass = localStorage.getItem("gementee-temp-pass") || null;
        while (enteredPass != pass) {
            enteredPass = prompt("Enter password");
            if (enteredPass == pass) {
                localStorage.setItem("gementee-temp-pass", pass);
            }
        }
    }

    document.title =
        "Eerlijk te Huur - Iedereen een eerlijke kans op een woning";

    const token = store.state.auth.token;
    const userType = to?.meta?.type ?? "user";
    const isProtected = to.meta.protected;
    const routeType = to.meta.type;
    // console.log({ token, userType, isProtected, routeType, to, from });
    if (isProtected) {
        if (!token) {
            let path = window.location.href.replace(window.location.origin, "");
            if (userType == "broker") {
                localStorage.setItem("broker_intended_route", path);
                return next(`/broker/login`);
            }

            localStorage.setItem("user_intended_route", path);
            return next(`/user/login`);
        }
        if (routeType !== "general" && routeType !== userType) return next("/");
    }

    next();
});

router.afterEach((to) => {
    // console.log("to,from", to, from);
    if (to.meta.protected) {
        store.dispatch("user/getUser");
    }
});

export default router;
