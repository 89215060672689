const state = () => ({
    accounts: [],
    transactions: [],
});

const getters = {
    accounts(state) {
        return state.accounts;
    },
    transactions(state) {
        return state.transactions;
        // let transactions = state.transactions;

        // transactions = transactions.filter(
        //     (tr) =>
        //         tr.transactionAmount.amount >= 1500 &&
        //         tr.transactionAmount.amount <= 10000
        // );

        // let data = {};

        // for (let tr of transactions) {
        //     if (data[tr.debtorName]) {
        //         data[tr.debtorName].push(tr);
        //     } else {
        //         data[tr.debtorName] = [tr];
        //     }
        // }

        // // transactions = _.uniqBy(transactions, function (tr) {
        // //     return tr.transactionAmount.amount;
        // // });

        // return data;
    },
};

const mutations = {
    setAccounts(state, accounts) {
        state.accounts = accounts;
    },
    setTransactions(state, transactions) {
        state.transactions = transactions;
    },
};

const actions = {
    async getAccounts({ commit, dispatch }, tenant_id) {
        try {
            commit("setAccounts", []);

            dispatch(
                "loadingError/setLoading",
                { type: "nordigen_accounts", state: true },
                { root: true }
            );

            dispatch(
                "loadingError/setError",
                { type: "nordigen_accounts", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/user/tenant/${tenant_id}/accounts`
            );

            commit("setAccounts", data.accounts);
            return data.accounts;
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;

            if (
                error?.response?.data?.data?.error_code == "PRODUCT_NOT_READY"
            ) {
                setTimeout(() => {
                    dispatch("getAccounts", tenant_id);
                }, 1000);
            } else {
                dispatch(
                    "loadingError/setError",
                    { type: "nordigen_accounts", state: message },
                    { root: true }
                );
                throw new Error(error);
            }
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "nordigen_accounts", state: false },
                { root: true }
            );
        }
    },
    async getTransactions({ commit, dispatch }, tenant_id) {
        try {
            commit("setTransactions", []);

            dispatch(
                "loadingError/setLoading",
                { type: "nordigen_transactions", state: true },
                { root: true }
            );

            dispatch(
                "loadingError/setError",
                { type: "nordigen_transactions", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/user/tenant/${tenant_id}/transactions`
            );

            // for (let tr of data.transactions) {
            //     if (tr.transactionAmount.amount > 1.5) {
            //         // transactions.push(`${tr.transactionAmount.amount} ${tr.transactionAmount.currency} - ${tr.debtorName}`);
            //     }
            // }
            // transactions = _.groupBy(transactions, tr => {
            //     return tr.transactionAmount.amount;
            // });
            // transactions = Object.keys(transactions);
            commit("setTransactions", data.transactions);
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            dispatch(
                "loadingError/setError",
                { type: "nordigen_transactions", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "nordigen_transactions", state: false },
                { root: true }
            );
        }
    },
    async setAccount({ dispatch }, { account_id, tenant_id }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "nordigen_set_account", state: true },
                { root: true }
            );

            dispatch(
                "loadingError/setError",
                { type: "nordigen_set_account", state: "" },
                { root: true }
            );

            let { data } = await window.axios.post(
                `/user/tenant/${tenant_id}/set-account`,
                { account_id }
            );

            return data;
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            dispatch(
                "loadingError/setError",
                { type: "nordigen_set_account", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "nordigen_set_account", state: false },
                { root: true }
            );
        }
    },
    async setTransaction({ dispatch }, { tenant_id, payload }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "nordigen_set_transaction", state: true },
                { root: true }
            );

            dispatch(
                "loadingError/setError",
                { type: "nordigen_set_transaction", state: "" },
                { root: true }
            );

            let { data } = await window.axios.post(
                `/user/tenant/${tenant_id}/set-transaction`,
                payload
            );
            return data;
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            dispatch(
                "loadingError/setError",
                { type: "nordigen_set_transaction", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "nordigen_set_transaction", state: false },
                { root: true }
            );
        }
    },
};

export const nordigen = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
