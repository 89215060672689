// import router from "@/router/index";
const state = () => ({
    appointments: [],
});

const getters = {
    getAppointments(state) {
        return state.appointments;
    },
    appointments(state) {
        return state.appointments;
    },
};

const mutations = {
    setAppointments(state, payload) {
        state.appointments = payload.appointments;
    },
};

const actions = {
    async getAppointments({ commit, dispatch }, payload = {}) {
        try {
            commit("setAppointments", []);
            payload.scheduled = true;
            dispatch(
                "loadingError/setLoading",
                { type: "calendar", state: true },
                { root: true }
            );

            dispatch(
                "loadingError/setError",
                { type: "calendar", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(
                `/broker/appointments`,
                payload
            );

            commit("setAppointments", data);
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "calendar", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "calendar", state: false },
                { root: true }
            );
        }
    },
};

export const calendar = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
