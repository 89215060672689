const state = () => ({
    tenant: null,
    otpSent: false,
});

const getters = {
    tenant(state) {
        return state.tenant;
    },
    otpSent(state) {
        return state.otpSent;
    },
};
const mutations = {
    setTenant(state, tenant) {
        state.tenant = tenant;
    },
    updateOTPSent(state, otpSent) {
        state.otpSent = otpSent;
    },
};

const actions = {
    async getTenant({ dispatch, commit }, tenant_id) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "get_tenant", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "get_tenant", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/user/tenant/${tenant_id}`
            );
            commit("setTenant", data.tenant);
            return data.tenant;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "get_tenant", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "get_tenant", state: false },
                { root: true }
            );
        }
    },
    async deleteTenant({ dispatch }, tenant_id) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "delete_tenant", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "delete_tenant", state: "" },
                { root: true }
            );

            const { data } = await window.axios.delete(
                `/user/tenant/${tenant_id}`
            );
            return data.tenant;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "delete_tenant", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "delete_tenant", state: false },
                { root: true }
            );
        }
    },
    async getTenantDetail({ dispatch, commit }, tenant_id) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "get_tenant_detail", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "get_tenant_detail", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/user/tenant/${tenant_id}/detail`
            );
            commit("setTenant", data.tenant);
            return data.tenant;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "get_tenant_detail", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "get_tenant_detail", state: false },
                { root: true }
            );
        }
    },
    async updateTenant({ dispatch, state, commit }, body) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "tenant", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "tenant", state: "" },
                { root: true }
            );

            body.tenant_id = body.tenant_id ?? state.tenant?.id;
            const { data } = await window.axios.post(`/user/tenant`, body);
            commit("setTenant", data.tenant);
            return data.tenant;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "tenant", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "tenant", state: false },
                { root: true }
            );
        }
    },
    async verify({ dispatch, commit }, payload) {
        const { query } = payload;
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "verification", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "verification", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(`/email/verify`, {
                params: query,
            });
            commit("setUserData", { ...state.user, verified: true });
            return data;
        } catch (error) {
            dispatch(
                "loadingError/setError",
                { type: "verification", state: error.message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "verification", state: false },
                { root: true }
            );
        }
    },
    async sendOTP({ dispatch, commit, state }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "sending_otp", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "sending_otp", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/user/tenant/${state.tenant.id}/send-otp`
            );
            commit("updateOTPSent", true);
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "sending_otp", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "sending_otp", state: false },
                { root: true }
            );
        }
    },
    async verifyOTP({ dispatch, commit, state }, otp) {
        console.log("state", state);
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "verify_otp", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "verify_otp", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(
                `/user/tenant/verify-otp`,
                {
                    tenant_id: state.tenant.id,
                    otp,
                }
            );

            commit("auth/setToken", data.access_token, { root: true });

            return data;
        } catch (error) {
            let message = error?.response?.data?.message ?? error.message;
            dispatch(
                "loadingError/setError",
                { type: "verify_otp", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "verify_otp", state: false },
                { root: true }
            );
        }
    },
    async getFiles({ dispatch, state }) {
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "getting_files", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "getting_files", state: "" },
                { root: true }
            );

            const { data } = await window.axios.get(
                `/user/tenant/${state.tenant.id}/file/list`
            );
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "getting_files", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "getting_files", state: false },
                { root: true }
            );
        }
    },
    async uploadFile({ dispatch, state }, { type, file }) {
        let formData = new FormData();
        formData.append("type", type);
        formData.append("file", file);
        try {
            dispatch(
                "loadingError/setLoading",
                { type: "file_upload", state: true },
                { root: true }
            );
            dispatch(
                "loadingError/setError",
                { type: "file_upload", state: "" },
                { root: true }
            );

            const { data } = await window.axios.post(
                `/user/tenant/${state.tenant.id}/file/upload`,
                formData
            );
            return data;
        } catch (error) {
            let message = error?.response?.data?.message
                ? error?.response?.data?.message
                : error.message;
            dispatch(
                "loadingError/setError",
                { type: "file_upload", state: message },
                { root: true }
            );
            throw new Error(error);
        } finally {
            dispatch(
                "loadingError/setLoading",
                { type: "file_upload", state: false },
                { root: true }
            );
        }
    },
};

export const tenantModule = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
